import { Component, OnInit, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { FiltersService } from 'src/app/services/filters.service';
import { State } from 'src/app/services/states.service';
import { TestcasesService } from 'src/app/services/testcases.service';
import { Options } from '@angular-slider/ngx-slider';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AppState } from 'src/app/store/app.state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-explorer-leftnav',
  templateUrl: './explorer-leftnav.component.html',
  styleUrls: ['./explorer-leftnav.component.css'],
})
export class ExplorerLeftnavComponent implements OnInit {
  selectedType: string = 'Coverage Verifier';
  selectedStates: any = '';
  showMore: boolean;
  product: any[] = [];
  statesInput: string[] = [];
  filterValues: any;
  filterSelection: any;
  isEmpty: boolean = false;
  productRemoved: any = false;
  public policyType: string[] = [];
  public policyStatus: any;
  public coverages: any;
  public lookBackPeriod: string[] = [];
  public coveragesSelection: any;
  public policyTypeSelection: any;
  public policyStatusSelection: any;
  public lookBackPeriodSelection: any;
  mulitpleCarriersDropdown: any = [{"code":"true", "description":"With"},{"code":"false", "description":"Without"}]
  public carrierSelected: any;
  public lapseSelected: any;
  vehicleLowValue: any ;
  vehicleHighValue: any;
  options: Options = { floor: 1, ceil: 10 };
  doptions: Options = { floor: 1, ceil: 10 };
  poptions: Options = { floor: 1, ceil: 10};
  policyCountLowValue: any;
  policyCountHighValue: any;
  driverLowValue: any;
  driverHighValue: any;
  driverHigh: any;
  driverLow: any;
  vehicleLow: any;
  vehicleHigh: any;
  policyHigh: any;
  policyLow: any;
  coverageMaxSelect: boolean = false;
  private stringFilterValues: [];
  private rangeFilterValues: [];
  private stringFilterSelection: [];
  private rangeFilterselection: [];
  private dateFilterSelection: [];
  private booleanFilterSelection: any;
  maxCoverageLimit:number = 10;
  filterBody: any;
  @ViewChild('coverage') coveragedropdown:NgSelectComponent;
  constructor(
    private cookie: CookieService,
    private testService: TestcasesService,
    private filtersService: FiltersService, private store: Store<AppState>
  ) {
    this.store.select(state => state.filterValueResponse).subscribe((data) => {
      if (!(Object.keys(data).length === 0)) {
        
        this.filterValues = data;
        this.stringFilterValues = this.filterValues.stringFilterValues;
          this.rangeFilterValues = this.filterValues.rangeFilterValues;
          let coverageTypeValues: any = this.stringFilterValues.find(
            (_: any) => _.name === 'Coverage'
          );
          this.coverages = coverageTypeValues?.values;

          let policyTypeValues: any = this.stringFilterValues.find(
            (_: any) => _.name === 'PolicyType'
          );
          this.policyType = policyTypeValues?.values;

          let policyStatusValues: any = this.stringFilterValues.find(
            (_: any) => _.name === 'PolicyStatus'
          );
          this.policyStatus = policyStatusValues?.values;

          let lookBackPeriodValues: any = this.stringFilterValues.find(
            (_: any) => _.name === 'LookbackPeriod'
          );

          this.lookBackPeriod = lookBackPeriodValues?.values;

          let driverValues: any = this.rangeFilterValues.find(
            (_: any) => _.name === 'NoOfDriver'
          );
          this.driverHigh =
            parseInt(driverValues.maxValue) == undefined
              ? 1
              : parseInt(driverValues.maxValue);
          this.driverLow =
            parseInt(driverValues.minValue) == undefined
              ? 10
              : parseInt(driverValues.minValue);

          this.doptions = {
            floor: parseInt(driverValues.minValue),
            ceil: parseInt(driverValues.maxValue),
          };

          let policyValues: any = this.rangeFilterValues.find(
            (_: any) => _.name === 'PolicyCount'
          );
          this.policyHigh =
            parseInt(policyValues.maxValue) == undefined
              ? 1
              : parseInt(policyValues.maxValue);
          this.policyLow =
            parseInt(policyValues.minValue) == undefined
              ? 10
              : parseInt(policyValues.minValue);

          this.poptions = {
            floor: parseInt(policyValues.minValue),
            ceil: parseInt(policyValues.maxValue),
          };
          
          let vehicleValues: any = this.rangeFilterValues.find(
            (_: any) => _.name === 'NoOfVehicle'
          );
          this.vehicleLow =
            parseInt(vehicleValues.minValue) == undefined
              ? 1
              : parseInt(vehicleValues.minValue);
          this.vehicleHigh =
            parseInt(vehicleValues.maxValue) == undefined
              ? 10
              : parseInt(vehicleValues.maxValue);
          this.options = {
            floor: parseInt(vehicleValues.minValue),
            ceil: parseInt(vehicleValues.maxValue),
          };
          
      }
    });
  
  }

  ngOnInit(): void {
    this.product = [{ product: 'Coverage Verifier' }];
    this.selectedStates = this.cookie.get('state');

    this.testService.stateChangedFilter.subscribe((data) => {
      this.setSelectedStates();
    });

    this.filtersService.applyFilter.subscribe((data) => {
      if (data) {
        let filterData = {
          policyTypeSelection: this.policyTypeSelection,
          policyStatusSelection: this.policyStatusSelection,
        };

        this.filtersService.filterData.next(filterData);
      }
    });
    this.testService.requestBody.subscribe((data) => {
      if (Object.keys(data).length > 0) {
        this.filterSelection = data;

        this.stringFilterSelection = this.filterSelection.StringFilters;
        this.rangeFilterselection = this.filterSelection.NumericRangeFilters;
        this.dateFilterSelection = this.filterSelection.DateFilters;
        this.booleanFilterSelection = this.filterSelection.BooleanFilters;

        let coverageTypeSelection: any = this.stringFilterSelection.find(
          (_: any) => _.Name === 'Coverages'
        );
        this.coveragesSelection =
          coverageTypeSelection?.Values == ''
            ? undefined
            : coverageTypeSelection?.Values;

        let policyTypeSelections: any = this.stringFilterSelection.find(
          (_: any) => _.Name === 'PolicyType'
        );

        this.policyTypeSelection =
          policyTypeSelections?.Values == ''
            ? undefined
            : policyTypeSelections?.Values[0];

        let policyStatusSelection: any = this.stringFilterSelection.find(
          (_: any) => _.Name === 'PolicyStatus'
        );
        this.policyStatusSelection =
          policyStatusSelection?.Values == ''
            ? undefined
            : policyStatusSelection?.Values[0];

        let lookBackPeriodSelections: any = this.dateFilterSelection?.find(
          (_: any) => _.Name === 'LookbackPeriod'
        );

        this.lookBackPeriodSelection =
          lookBackPeriodSelections?.StartValue == ''
            ? undefined
            : lookBackPeriodSelections?.StartValue;

        let MultipleCarrierSelections: any = this.booleanFilterSelection?.find(
          (_: any) => _.Name === 'MultipleCarrier'
        );

        this.carrierSelected = 
        MultipleCarrierSelections?.Value == ''
          ? undefined
          : MultipleCarrierSelections?.Value;

        let LapseInCoverageSelections: any = this.booleanFilterSelection?.find(
          (_: any) => _.Name === 'LapseInCoverage'
        );

        this.lapseSelected =
        LapseInCoverageSelections?.Value == ''
          ? undefined
          : LapseInCoverageSelections?.Value;

        let NumberOfDriverSelections: any = this.rangeFilterselection?.find(
          (_: any) => _.Name === 'NoOfDriver'
        );

        this.driverHighValue = NumberOfDriverSelections?.EndValue ? 
        parseInt(NumberOfDriverSelections.EndValue) : this.doptions.ceil;
        
        this.driverLowValue = NumberOfDriverSelections?.StartValue ? 
        parseInt(NumberOfDriverSelections.StartValue) : this.doptions.floor;

        let NumberOfVehicleSelections: any = this.rangeFilterselection?.find(
          (_: any) => _.Name === 'NoOfVehicle'
        );

        this.vehicleHighValue = NumberOfVehicleSelections?.EndValue ? 
        parseInt(NumberOfVehicleSelections.EndValue) : this.options.ceil;
        this.vehicleLowValue = NumberOfVehicleSelections?.StartValue ? 
        parseInt(NumberOfVehicleSelections.StartValue): this.options.floor;

        let NumberOfPolicySelections: any = this.rangeFilterselection?.find(
          (_: any) => _.Name === 'PolicyCount'
        );

        this.policyCountHighValue = NumberOfPolicySelections?.EndValue ? 
        parseInt(NumberOfPolicySelections.EndValue): this.poptions.ceil;
        this.policyCountLowValue = NumberOfPolicySelections?.StartValue ? 
        parseInt(NumberOfPolicySelections.StartValue): this.poptions.floor;
      
      } else this.showMore = false;
    });
    if (
      this.lapseSelected != undefined ||
      this.carrierSelected != undefined ||
      this.coveragesSelection != undefined ||
      this.policyStatusSelection != undefined ||
      this.policyTypeSelection != undefined ||
      this.lookBackPeriodSelection != undefined ||
      (this.driverLowValue != undefined &&
        this.driverLowValue != this.driverLow) ||
      (this.driverHighValue != undefined && 
        this.driverHighValue != this.driverHigh) ||
      (this.vehicleLowValue != undefined &&
        this.vehicleLowValue != this.vehicleLow) ||
      (this.vehicleHighValue != undefined && 
        this.vehicleHighValue != this.vehicleHigh) ||
      (this.policyCountLowValue != undefined &&
        this.policyCountLowValue != this.policyLow) ||
      (this.policyCountHighValue != undefined && 
        this.policyCountHighValue != this.policyHigh)
    ) {
      this.showMore = true;
    } else {
      this.showMore = false;
      
    }
  }

  setSelectedStates() {
    let state: any;
    if (this.cookie.get('state') != '') {
      state = JSON.parse(this.cookie.get('state'));
      state = state.map((_) => _.index);
    }
    this.statesInput = state;
    this.selectedStates = this.cookie.get('state');
  }

  statesSelected(states: State[]) {
    if (states.length > 0) {
      this.isEmpty = false;
    } else {
      this.isEmpty = true;
    }
    let obj: any[] = [];
    states.forEach((i) => {
      let jObj = {
        index: i.index,
        name: i.name,
      };

      obj.push(jObj);
    });

    this.selectedStates = JSON.stringify(obj);
    this.statesInput = states.map(i => i.index);
  }
  showAdditionalFilters() {
    this.showMore = true;
    if (
      this.vehicleHighValue == undefined ||
      this.vehicleLowValue == undefined
    ) {
      this.vehicleLowValue = this.vehicleLow;
      this.vehicleHighValue = this.vehicleHigh;
      this.driverHighValue = this.driverHigh;
      this.driverLowValue = this.driverLow;
      this.policyCountLowValue = this.policyLow;
      this.policyCountHighValue = this.policyHigh;
    }
  }
  saveFilter() {
    this.cookie.set('state', this.selectedStates);
    this.testService.stateChanged(this.selectedStates);
    this.filtersService.applyFilter.next(true);
    this.filterBodyGenerator();
    sessionStorage.setItem('filters', JSON.stringify(this.filterBody));
  }
  resetFilters() {
    this.cookie.set('state', this.selectedStates);
    this.testService.stateChanged(this.selectedStates);
    this.coveragesSelection = [];
    this.lookBackPeriodSelection = undefined;
    this.policyTypeSelection = undefined;
    this.policyStatusSelection = undefined;
    this.lookBackPeriodSelection = undefined;
    this.carrierSelected = undefined;
    this.lapseSelected = undefined;
    this.coverageMaxSelect = false;
    let driverValues: any = this.rangeFilterValues.find(
      (_: any) => _.name === 'NoOfDriver'
    );
    this.driverHighValue = parseInt(driverValues.maxValue);
    this.driverLowValue = parseInt(driverValues.minValue);
    let vehicleValues: any = this.rangeFilterValues.find(
      (_: any) => _.name === 'NoOfVehicle'
    );
    this.vehicleLowValue = parseInt(vehicleValues.minValue);
    this.vehicleHighValue = parseInt(vehicleValues.maxValue);
    
    let policyValues: any = this.rangeFilterValues.find(
      (_: any) => _.name === 'PolicyCount'
    );
    this.policyCountLowValue = parseInt(policyValues.minValue);
    this.policyCountHighValue = parseInt(policyValues.maxValue);
    
    this.filterBodyGenerator();
  }
  filterBodyGenerator() {
    this.testService.requestBody.next({
      UserId: 'saumil',
      StringFilters: [
        {
          Name: 'State',
          Values:
            this.selectedStates == ''
              ? ['']
              : JSON.parse(this.selectedStates).map((_) => _.name),
        },
        {
          Name: 'Coverages',
          Values:
            this.coveragesSelection === undefined
              ? ['']
              : this.coveragesSelection,
        },
        {
          Name: 'PolicyStatus',
          Values: [
            this.policyStatusSelection == undefined || null
              ? ''
              : this.policyStatusSelection,
          ],
        },
        {
          Name: 'PolicyType',
          Values: [
            this.policyTypeSelection == undefined || null
              ? ''
              : this.policyTypeSelection,
          ],
        },
      ],
      NumericRangeFilters: [
        {
          Name: 'NoOfDriver',
          StartValue:
            this.driverLowValue == undefined
              ? '' + this.driverLow
              : '' + this.driverLowValue,
          EndValue:
            this.driverHighValue == undefined
              ? '' + this.driverHigh
              : '' + this.driverHighValue,
        },
        {
          Name: 'NoOfVehicle',
          StartValue:
            this.vehicleLowValue == undefined
              ? '' + this.vehicleLow
              : '' + this.vehicleLowValue,
          EndValue:
            this.vehicleHighValue == undefined
              ? '' + this.vehicleHigh
              : '' + this.vehicleHighValue,
        },  
        {
          Name: 'PolicyCount',
          StartValue:
            this.policyCountLowValue == undefined
              ? '' + this.policyLow
              : '' + this.policyCountLowValue,
          EndValue:
            this.policyCountHighValue == undefined
              ? '' + this.policyHigh
              : '' + this.policyCountHighValue,
        },
      ],
      BooleanFilters: [
        {
          Name: 'MultipleCarrier',
          Value:
            this.carrierSelected == undefined || null
              ? ''
              : '' + this.carrierSelected,
        },
        {
          Name: 'LapseInCoverage',
          Value:
            this.lapseSelected == undefined || null
              ? ''
              : '' + this.lapseSelected,
        },
      ],
      DateFilters: [
        {
          Name: 'LookbackPeriod',
          StartValue:
            this.lookBackPeriodSelection == undefined || null
              ? ''
              : this.lookBackPeriodSelection,
          Operation: 'GreaterOrEqual',
          EndValue: null,
        },
      ],
    });
  }

  productChange() {
    if (this.selectedType == null) {
      this.productRemoved = true;
    } else {
      this.productRemoved = false;
    }
  }
  coverageSelection() {
    if (this.coveragesSelection.length == 10) {
       this.coveragedropdown.close();
       this.coverageMaxSelect = true;
    } else {
       this.coverageMaxSelect = false;
    }
  }
}
