import { Component,OnInit } from '@angular/core';
import { IAppConfig } from '../../../models/config';
import { AppService } from '../../../app.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html'
})
export class AuthComponent implements OnInit {

  appConfig: IAppConfig;
  redirectUrl: string;
  clientId: string;
  loginUrl: string;
  logoutUrl: string;
  responseType: string;
  issuer: string;

  constructor(private appService: AppService) {}

  ngOnInit(): void {
    this.appConfig = this.appService.readConfig();
    this.clientId = this.appConfig.clientId;
    this.loginUrl = this.appConfig.loginUrl;
    this.issuer = this.appConfig.issuer;
    this.responseType = this.appConfig.responseType;
    this.logoutUrl = this.appConfig.logoutUrl;
  
}
}