import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppService } from '../app.service';
@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  apiUrl: string;
  public productsData = new BehaviorSubject<any>([]);
  constructor(private http: HttpClient, private appService: AppService) {}
  

  public getProducts(body): Observable<any> {
    this.apiUrl = this.appService.configuration.apiUrl;
    let url = `${this.apiUrl}/products`;
    return this.http.post(url, body) as Observable<any>;
  }
}
