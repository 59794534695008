import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { State } from 'src/app/services/states.service';
import { TestcasesService } from 'src/app/services/testcases.service';

@Component({
  selector: 'app-modal-pop-up',
  templateUrl: './modal-pop-up.component.html',
  styleUrls: ['./modal-pop-up.component.css']
})
export class ModalPopUpComponent {

  private selectedStates;
  public isEmpty: boolean = true;

  constructor(public activeModal: NgbActiveModal, private cookie: CookieService, private testService:TestcasesService) {

   }

  statesSelected(states: State[]) {

    if(states.length > 0) {
      this.isEmpty = false;
    }
    else{
      this.isEmpty = true;
    }
    let obj:any[] = [];
    states.forEach(i => {

      let jObj = {
        index : i.index,
        name:i.name
      }

      obj.push(jObj);

    })
    
    this.selectedStates = JSON.stringify(obj);

  }

  saveState() {
    this.cookie.set("state", this.selectedStates);
    this.testService.stateChanged(this.selectedStates);
    this.testService.requestBody.next({
      UserId : "saumil",
      StringFilters : [
           {Name : "State" , Values : this.selectedStates == ""?[""]:JSON?.parse(this.selectedStates).map((_)=>_.name)}]});
  }
  
}
