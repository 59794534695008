
<div class="page-wrapper">
  <h1>Test Case Manager Dashboard</h1>
  <p class="tag-line">Select a product below:</p>
  <div class="cards-wrapper">
    <div class="card-wrapper mt-5">
      <lib-spinner [isBusy]="loading" [backdrop]="false" [type]="'center'" [small]="true"></lib-spinner>

      <app-dash-board-card
        *ngFor="let item of data"
        [data]="item"
      ></app-dash-board-card>
    </div>
  </div>
</div>
