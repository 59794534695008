
<div class="page-wrapper" style="margin-right: 90px">
  <h1 style="display: inline-block">Test Case Groups</h1>
  <div>
    <div [ngClass]="{ 'search-result-grid-disable': loading }">
      <strong>{{ data?.length | number : "1.0" }} groups found.</strong>
    </div>
    <lib-spinner
      [isBusy]="loading"
      [backdrop]="false"
      [type]="'center'"
      [small]="true"
    ></lib-spinner>
    <lib-grid
      [columns]="cols"
      [data]="data"
      [dataKey]="dataKey"
      [selectableRows]="false"
      [expandableRows]="false"
      [exportData]="false"
      [multiSelectRows]="false"
      [statusOptions]="statusOptions"
      [defaultSort]="defaultSort"
      [globalFilter]="globalFilter"
      (CellClick)="groupCellClick($event)"
      [cellTemplates]="{ groupEditDeleteTemplate: groupEditDeleteTemplate }"
      [ngClass]="{ 'search-result-grid-disable': loading }"
    >
    </lib-grid>
    <ng-template
      #groupEditDeleteTemplate
      let-rowData="rowData"
      let-field="field"
    >
      <div class="inner-block">
        <i
          class="va-icon-mode_edit"
          style="color: #10a9e0; padding-right: 10px; cursor: pointer"
          (click)="editGroup(rowData)"
          title="Edit"
        ></i>
        <i
          class="va-icon-delete"
          style="color: #10a9e0; cursor: pointer"
          (click)="deleteGroup(rowData)"
          title="Delete"
        ></i>
      </div>
    </ng-template>
  </div>
</div>
