import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import { AppService } from '../app.service';
import { finalize } from 'rxjs/operators';
import { ToastService } from '@uw-verisk/common-ui';
import { InviteUser, ListUser } from '../models/userinformation';

@Injectable({
  providedIn: 'root',
})
export class TestcasesService {
  stateChangedFilter: EventEmitter<any>;
  apiUrl: string ;
  params: any;
  downloadToaster: any;
  public requestBody = new BehaviorSubject<any>({});
  private count =0;
  private spinner = new BehaviorSubject<string>('');

  constructor(private http: HttpClient,private appService: AppService, private toaster:ToastService) {
    this.stateChangedFilter = new EventEmitter<any>();
  }
  
  getSpinnerObserver(): Observable<string>{
   return this.spinner.asObservable();
  }

  requestStarted(){
    if(++this.count===1){
      this.spinner.next('start');
    }
  }
  
  requestEnded(){
    if(this.count===0 || --this.count === 0){
      this.spinner.next('stop');
    }
  }
  
  requestReset(){
    this.count = 0;
    this.spinner.next('stop');
  }
  

  public testCases(body) {
    this.apiUrl = this.apiUrl ? this.apiUrl : this.appService.configuration.apiUrl;
    let url = `${this.apiUrl}/CV/TestCases`;
    this.requestStarted();
    return this.http.post(url, body).pipe( finalize(() => {
      this.requestEnded();
    }),) as Observable<any>;
  }

  public stateChanged(data?: any) {
    this.stateChangedFilter.emit(data);
    return data ?? '';
  }

  public downloadReport(body):Observable<any>{
    this.apiUrl = this.appService.configuration.apiUrl;
    let url = `${this.apiUrl}/CV/ExportToExcel`;
    this.toaster.info("downloading..."); 
    return this.http.post(url,body,{responseType:'blob'}).pipe( finalize(() => {
     this.toaster.success('Download successful');
    }));
  }
  public getAllTestCaseGroups(){
    this.apiUrl = this.apiUrl ? this.apiUrl : this.appService.configuration.apiUrl;
    let url = `${this.apiUrl}/testCaseGroup/getAll`;
    this.requestStarted();
    return this.http.post(url,{ "productCode": "CV"}).pipe( finalize(() => {
      this.requestEnded();
    }),)  as Observable<any>;
  }
  public insertTestCaseGroup(data):Observable<any>{
    this.apiUrl = this.appService.configuration.apiUrl;
    let url = `${this.apiUrl}/testCaseGroup/insert`;
    return this.http.post(url,data);
  }
  public deleteTestCaseGroup(data):Observable<any>{
    this.apiUrl = this.appService.configuration.apiUrl;
    let url = `${this.apiUrl}/testCaseGroup/delete`;
    return this.http.post(url,{"testCaseGroupUniqueId": data});
  }
  public editTestCaseGroup(data):Observable<any>{
    this.apiUrl = this.appService.configuration.apiUrl;
    let url = `${this.apiUrl}/testCaseGroup/updateMetaData`;
    return this.http.post(url,data);
  }
  public getTestCaseGroupDetails(data){
    this.apiUrl = this.appService.configuration.apiUrl;
    this.requestStarted();
    let url = `${this.apiUrl}/CV/getTestCasesByGroupUniqueId/${data}`;
    return this.http.get(url).pipe( finalize(() => {
      this.requestEnded();
    }),) as Observable<any>;
  }
  public removeTestCasesFromGroup(data):Observable<any>{
    this.apiUrl = this.appService.configuration.apiUrl;
    let url = `${this.apiUrl}/testCaseGroup/removeTestCases`;
    return this.http.post(url,data);
  }
  public listUsers(productCode :string): Observable<ListUser[]> {
    this.apiUrl = this.appService.configuration.contributionsApiUrl;
    let url = `${this.apiUrl}Security/ListUsers?productCode=` + productCode;
    return this.http.get(url) as Observable<ListUser[]>;
  }
  public getEmailHost() : Observable <any> {
    this.apiUrl = this.appService.configuration.contributionsApiUrl;
    let url = `${this.apiUrl}Security/GetEmailHost`;
    return this.http.get(url) as Observable<any>;
  }
  public getPermissionGroup(productIdentity) : Observable <any> {
    this.apiUrl = this.appService.configuration.contributionsApiUrl;
    let url = `${this.apiUrl}Security/GetAuthorizationGroup?productIdentity=`+ productIdentity;
    return this.http.get(url) as Observable<any>;
  }
  public inviteUser(userInfo: InviteUser): Observable<InviteUser> {
    this.apiUrl = this.appService.configuration.contributionsApiUrl;
    let url = `${this.apiUrl}Security/InviteUser`;
    this.params = userInfo;
    return this.http.post(url, userInfo) as Observable<InviteUser>;
  }
  public editUser(userInfo): Observable<any> {
    this.apiUrl = this.appService.configuration.contributionsApiUrl;
    let url = `${this.apiUrl}Security/EditUserAuthorizationGroup`;
    return this.http.post(url, userInfo) as Observable<any>;
  }
  
  public revokeUserProductPermission(revokeRequest) : Observable<any>{
    this.apiUrl = this.appService.configuration.contributionsApiUrl;
    let url = `${this.apiUrl}Security/RevokeProductAccessByUserAuthGroup`;
    return this.http.request('delete',url, {body: revokeRequest}) as Observable<any>;
  } 
} 
