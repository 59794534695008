import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TestcasesService } from 'src/app/services/testcases.service';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.css']
})
export class InviteComponent implements OnInit {

  @Input() public productId: string;
  @Input() public customerId: string;

  emailDomain: any = [];
  rolePermissions :any;
  selectedRole = null; 
  email: any;
  role : any;
  defaultEmail: string = "";
  defaultRole: string = 'Select Permissions Group';
  namePattern: string = "^[a-zA-Z0-9][a-zA-Z0-9-._\']*[a-zA-Z0-9]+$";
  emailPattern: string = "^[a-zA-Z0-9][a-zA-Z0-9-._\']*[a-zA-Z0-9]*@[a-zA-Z0-9]+([-.][a-zA-Z0-9]+)*\.[a-zA-Z0-9]+([-.][a-zA-Z0-9]+)*$";
  submitted = false;
  errorMessage: any;
  emailId: any;
  checkEmail: any;
  maxLengthEmail: any;

  constructor(public modal: NgbActiveModal, private fb: FormBuilder, private service: TestcasesService) { }
  inviteUserForm = this.fb.group({
    name: ['', [Validators.required, Validators.pattern(this.namePattern)]],
    email: [this.defaultEmail, Validators.required],
    role: ['', Validators.required]
  });
  
  ngOnInit(): void {
    this.errorMessage = false;
    this.service.getPermissionGroup(this.productId).subscribe(res =>{
      Object.entries(res.authorizationGroups).forEach(([key, value]) => {
        let groupName = ((value['authorizationGroupName']).split('-')[2]).split(' ')[0];
        value['groupName'] = groupName;
      } );
      
      this.rolePermissions = res.authorizationGroups;   
    });
    this.service.getEmailHost().subscribe(res =>{
      Object.entries(res).forEach(([key, value]) => {
        this.emailDomain.push("@" + value['emailHostName']);
      });
      this.defaultEmail = this.emailDomain[0];
      this.inviteUserForm.controls['email'].setValue(this.defaultEmail, {onlySelf: true});
    })
  }

  get form() { return this.inviteUserForm.controls; }

  onSubmit() {
   
    this.emailId = this.inviteUserForm.value.name + this.inviteUserForm.value.email;
    this.checkEmail = false;
    this.maxLengthEmail = false;
    if(this.emailId) {
      this.checkEmail = this.emailId.match(this.emailPattern);
      if( this.emailId.length > 100 ) {
        this.maxLengthEmail = true;
      }
    }
    this.submitted = true;
    this.errorMessage = false;
    if (this.inviteUserForm.invalid || (!this.inviteUserForm.invalid && this.checkEmail == null  || this.maxLengthEmail)) {
      return;
    }
    
    let userInfo = {
      emailId :this.inviteUserForm.value.name + this.inviteUserForm.value.email,
      authorizationGroupId : parseInt(this.inviteUserForm.value.role),
      productId : parseInt(this.productId)
    }
    this.service.inviteUser(userInfo).subscribe(result =>{
      if(result && 'code' in result) {
        this.modal.dismiss(1);
      }
      else if(result && 'errorCode' in result && result['errorCode'] == 'UM1001') {
        this.errorMessage =  "Invalid email Domain";
      }
      else if(result && 'errorCode' in result && result['errorCode'] == 'UM1002') {
        this.errorMessage = "Invalid Authorization group";
      }
      else if(result && 'errorCode' in result && result['errorCode'] == 'UM1003') {
        this.errorMessage =  "User already exists";
      }
      else if(result && 'errorCode' in result && result['errorCode'] == 'UM1005') {
        this.errorMessage =  "User already belongs to a customer and it can't be invited for another customer";
      }
    })
   }

}
