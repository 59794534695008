import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridComponent } from '@uw-verisk/grid';
import { CookieService } from 'ngx-cookie-service';
import { testCase } from 'src/app/models/testCase';
import { PolicyDetailsService } from 'src/app/services/policy-details.service';
import { TestcasesService } from 'src/app/services/testcases.service';
import { ModalPopUpComponent } from '../shared/modal-pop-up/modal-pop-up.component';
import { GroupsModalComponent } from '../test-case-groups/groups-modal/groups-modal.component';

@Component({
  selector: 'app-test-case-explorer',
  templateUrl: './test-case-explorer.component.html',
  styleUrls: ['./test-case-explorer.component.css'],
})
export class TestCaseExplorerComponent implements OnInit {
  showNoRecords: any = false;
  loading: any = true;
  isLoaded: any = false;
  pageSize: any = 1000;
  cols: any = [
    {
      field: 'testCaseId',
      header: 'Test Case ID',
      filter: false,
      sortable: true,
      qspassthrough: true,
      clickeable: true,
    },
    {
      field: 'noOfPolicies',
      header: 'Policy Count',
      sortable: true,
      qspassthrough: true,
    },
    {
      field: 'state',
      header: 'State',
      sortable: true,
      qspassthrough: true,
    },
    {
      field: 'noOfDrivers',
      header: 'Driver Count',
      sortable: true,
      qspassthrough: true,
    },
    {
      field: 'noOfVehicles',
      header: 'Vehicle Count',
      sortable: true,
      qspassthrough: true,
    },
    {
      field: 'lapseInCoverage',
      header: 'Lapse',
      sortable: true,
      qspassthrough: true,
    },
    {
      field: 'multipleCarriers',
      header: 'Multiple Carriers',
      sortable: false,
      qspassthrough: true,
    },
    {
      field: 'groups.name',
      header: 'Test Case Groups',
      cellTemplate: 'testcaseGroupTemplate',
      sortable: false,
      qspassthrough: true,
    },
  ];
  testcasedata: testCase[] = [];
  data: any = this.testcasedata;
  requestBody: any;
  dataKey: any = 'testCaseId';
  defaultSort = { field: 'dateAdded', sortMode: -1 };
  statusOptions: any = { OPEN: 'label-success', EXPIRED: 'label-warning' };
  globalFilter = '';
  buttonConfig = [
    {
      label: 'Export',
      action: 'export',
      bulkAction: true,
      btnClass: 'btn-primary',
      btnIcon: 'fa',
      resourcename: 'export:edit',
    },
    {
      label: 'Test Case Groups',
      action: 'testCaseGroups',
      bulkAction: true,
      btnClass: 'btn-primary',
      btnIcon: 'fa',
      resourcename: 'export:edit',
    },
  ];
  @ViewChild('explorerGrid') explorerGridRef: GridComponent;
  cellClicked: boolean = false;
  constructor(
    private modalService: NgbModal,
    private service: TestcasesService,
    private cookie: CookieService,
    private router: Router,
    private policyService: PolicyDetailsService,
    private testService: TestcasesService
  ) {}

  ngOnInit(): void {
    this.testService.requestBody.subscribe((data) => {
      if (Object.keys(data).length > 0) {
        this.requestBody = data;
        this.getAllTestCases(this.requestBody);
      } else {
        this.getAllTestCases({
          UserId: 'saumil',
          StringFilters: [
            {
              Name: 'State',
              Values:
                this.cookie.get('state') == ''
                  ? ['']
                  : JSON.parse(this.cookie.get('state')).map((_) => _.name),
            },
            { Name: 'Coverages', Values: [''] },
            { Name: 'PolicyStatus', Values: [''] },
            { Name: 'PolicyType', Values: [''] },
          ],
          NumericRangeFilters: [
            { Name: 'NoOfDriver', StartValue: '1', EndValue: '10' },
            { Name: 'NoOfVehicle', StartValue: '1', EndValue: '10' },
          ],
          BooleanFilters: [
          ],
          DateFilters: [
            {
              Name: 'LookbackPeriod',
              StartValue: '',
              Operation: 'GreaterOrEqual',
              EndValue: null,
            },
          ],
        });
      }
    });

    if (this.cookie.get('state') == '') {
      this.modalService.open(ModalPopUpComponent, {
        centered: true,
        backdrop: 'static',
        backdropClass: 'backdrop-class',
      });
    }
  }

  public getAllTestCases(body) {
    let state: any;
    if (this.cookie.get('state') != '') {
      state = JSON.parse(this.cookie.get('state'));
      state = state.map((_) => _.name);
    }
    this.testService.getSpinnerObserver().subscribe((status) => {
      this.loading = status === 'start';
    })
    let resp = this.service.testCases(body);
    resp.subscribe((cases) => {
      this.data = cases as testCase[];
      this.pageSize = this.data.length;
      if (state != undefined) {
        this.data = this.data.filter((x) => state.includes(x.state));
        if (this.data.length == 0) {
          this.showNoRecords = true;
          this.isLoaded = false;
        } else {
          this.showNoRecords = false;
          this.isLoaded = true;
        }
      }
    });
  }

  cellClick(event) {
    let uniqueIds = event.rowData.uniqueId;
    this.cellClicked = true;
    localStorage.setItem('uniqueIds', uniqueIds);
    this.router.navigateByUrl('/CoverageVerifier/policySearch');
  }
  groupClick(group) {
    localStorage.setItem('groupUniqueIds', group.uniqueId);
    localStorage.setItem('name', group.name);
    localStorage.setItem('description', group.description);
    this.router.navigateByUrl('CoverageVerifier/testCaseGroupsDetails');
  }
  selectedRows(event) {
    if (event.action === 'export') {
      let uniqueIds = event.selectedRowData.map((_) => _.uniqueId);
      this.service.downloadReport(uniqueIds).subscribe((data: Blob) => {
        let downloadUrl = URL.createObjectURL(data);
        let link = document.createElement('a');
        link.href = downloadUrl;
        link.download = 'testcase.xlsx';
        link.click();
      });
    }
    else if (event.action === 'testCaseGroups') {
      let uniqueId = event.selectedRowData.map(({ uniqueId, testCaseId, state, noOfPolicies }) => ({uniqueId, testCaseId, state, noOfPolicies}));
      const modalRef = this.modalService.open(GroupsModalComponent, {
        centered: true,
        backdrop: 'static',
        backdropClass: 'backdrop-class',
      });
      modalRef.componentInstance.uniqueId = uniqueId;
      modalRef.componentInstance.label = "Add To Test Case Group";
    }
    this.explorerGridRef.selectedRowData = [];
  }
}
