
<div class="card mr-3 mb-3">
  <div class="card-body">
    <h5 class="card-title">{{ name }}</h5>

    <p class="card-text">
      {{ description }}
    </p>
    <div>
      <div class="dashboard-card-layout">
        <p>Last Refreshed:</p>
        <p class="dashboard-card-values">{{ lastRefreshed }}</p>
      </div>
      <div class="dashboard-test-case-layout">
        <p>Test Cases:</p>
        <p class="dashboard-card-values">
          {{ noOfTestCases | number : "1.0" }}
        </p>
      </div>
    </div>
    <a
      class="card-link"
      [routerLink]="['./CoverageVerifier/testcaseExplorer']"
      class="card-link"
      id="cardSelection-{{ name }}"
      >Select
      </a>
  </div>
</div>
