import { createAction, props } from '@ngrx/store';
import { TestCaseFilter } from '../model/test-case-explorer-interface';

export const TEST_CASE_FILTERS= '[Test Case Explorer] Filter';
export const TEST_CASE_DATA= '[Test Case Explorer] Data';
export const TEST_CASE_ERROR= '[Test Case Explorer] Error';

export const testCaseFilters= createAction(
    TEST_CASE_FILTERS,
    props<{filter: TestCaseFilter}>()
);
export const testCaseData= createAction(
    TEST_CASE_DATA,
    props<{data: any}>()
);
export const testCaseError= createAction(
    TEST_CASE_ERROR,
    props<{message: any}>()
);
