import { Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PolicySearchComponent } from './components/policy-search/policy-search.component';
import { UsermanagementComponent } from './components/shared/components/usermanagement/usermanagement.component';
import { TestCaseExplorerComponent } from './components/test-case-explorer/test-case-explorer.component';
import { GroupsDetailsComponent } from './components/test-case-groups/groups-details/groups-details.component';
import { TestCaseGroupsComponent } from './components/test-case-groups/test-case-groups.component';
import { VrskGatewayLoginGuard, VrskOktaLoginFlowComponent } from '@uw-verisk/auth-okta';
import { OktaCallbackComponent } from '@okta/okta-angular';

export const rootRouterConfig: Routes = [
    { path: `auth/oktaLoginFlow`, component: VrskOktaLoginFlowComponent },
    { path: 'auth/callback', component: OktaCallbackComponent},
    { path: 'auth/logged-out', pathMatch: 'full', redirectTo: '' },
    { path: '', component: DashboardComponent, canActivate: [VrskGatewayLoginGuard]},
    { path: 'CoverageVerifier/testcaseExplorer', component: TestCaseExplorerComponent, canActivate: [VrskGatewayLoginGuard]},
    { path: 'CoverageVerifier/policySearch', component: PolicySearchComponent, canActivate: [VrskGatewayLoginGuard]},
    { path: 'CoverageVerifier/testCaseGroups', component: TestCaseGroupsComponent, canActivate: [VrskGatewayLoginGuard]},
    { path: 'CoverageVerifier/testCaseGroupsDetails', component: GroupsDetailsComponent, canActivate: [VrskGatewayLoginGuard]},
    { path: 'userManagement', component: UsermanagementComponent, canActivate: [VrskGatewayLoginGuard]},
];