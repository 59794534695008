
<lib-side-nav
  *ngIf="IsHome||IsExplorer||IsGroup"
  [selectedItem]="selectedItem"
  (selectionChange)="selectionChange($event)"
  [appConfig]="appConfig"
  id="products"
  class="productCustom"
></lib-side-nav>
<lib-side-nav
  *ngIf="IsExplorer || IsGroup"
  [selectedItem]="viewBy"
  [appConfig]="viewByDropdown"
  (selectionChange)="groupsSelectionChange($event)"
></lib-side-nav>

<div class="left-nav" *ngIf="IsExplorer">
  <app-explorer-leftnav></app-explorer-leftnav>
</div>

<div class="left-nav" *ngIf="IsPolicy">
  <app-policy-leftnav></app-policy-leftnav>
</div>