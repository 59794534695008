
<div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close" (click)="activeModal.close(true)" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p> {{message}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="triggerEvent()">Yes </button>
      <button type="button" class="btn btn-secondary" (click)="activeModal.close(true)">No </button>
    </div>
  </div>