
<div class="modal-body container">
  <div class="row">
    <div class="col-10">
      <h1>{{ label }}</h1>
    </div>
    <div class="col-2">
      <h2 class="pointer-cursor" (click)="activeModal.dismiss()">X </h2>
    </div>
  </div>

  <div class="row justify-content-md-center">
    <div
      id="loading-spinner"
      *ngIf="label === 'Add To Test Case Group'"
      class="col-md-auto"
    >
      <lib-spinner
        [isBusy]="loading"
        [backdrop]="false"
        [type]="'inline'"
        [small]="true"
      ></lib-spinner>
    </div>
  </div>
  
  <div [ngClass]="{ 'modal-disable': (label === 'Add To Test Case Group' && loading) }">
    <div class="row">
      <div class="col" *ngIf="label === 'Add To Test Case Group'">
        <p class="test-case-groups-validation">
          Add selected test cases to an existing Test Case Group or Create a New
          Group. Selected Test Case(s): {{ uniqueId?.length }}
        </p>
      </div>
    </div>
    <form [formGroup]="groupForm" (ngSubmit)="createGroup()">
      <div class="row">
        <div class="col" *ngIf="label === 'Add To Test Case Group'">
          <ng-select
            id="testCaseGroup"
            class="groups"
            [items]="groupsDropdown"
            bindLabel="name"
            bindValue="testCaseGroupUniqueId"
            placeholder="Select Test Case Group"
            formControlName="testCaseGroupUniqueId"
            dropdownPosition="bottom"
            (change)="inputChange()"
            (clear)="inputEnable()"
          >
          </ng-select>
        </div>
      </div>
      <div
        class="row justify-content-md-center"
        *ngIf="label === 'Add To Test Case Group'"
      >
        <div
          id="test-case-group-label"
          class="col-md-auto"
        >
          OR
        </div>
      </div>
      <div class="row">
        <div class="col test-case-groups-validation" *ngIf="maxGroups">
          Limit reached. A maximum of 10 Groups can be created.
        </div>
      </div>
      <div class="row" *ngIf="nameRequired">
        <div class="col test-case-groups-validation">
          * Required
        </div>
      </div>
      <div class="row" *ngIf="label === 'Edit Test Case Group'">
        <div id="edit-group-label" class="col">Test Case Group Name</div>
      </div>
      <div class="row">
        <div id="bottom-padding" class="col">
          <input
            class="form-control"
            type="text"
            formControlName="name"
            placeholder="New Test Case Group Name"
            maxlength="30"
          />
        </div>
      </div>

      <div class="row" *ngIf="label === 'Edit Test Case Group'">
        <div id="edit-group-label" class="col">Group Description</div>
      </div>
      <div class="row">
        <div id="bottom-padding" class="col">
          <input
            class="form-control"
            type="text"
            name="description"
            formControlName="description"
            placeholder="Group Description"
          />
        </div>
      </div>
      <div class="row">
        <div class="col test-case-groups-validation" *ngIf="minTestCase">
          Minimum 1 test case needs to be selected
        </div>
      </div>
      <div class="row">
        <div
        id="bottom-padding" class="col"
          *ngIf="label === 'Add To Test Case Group'"
        >
          <lib-grid
            class="groupsModalGrid"
            #groupsModal
            [columns]="cols"
            [data]="uniqueId"
            [dataKey]="dataKey"
            [selectableRows]="false"
            [expandableRows]="false"
            [exportData]="false"
            [multiSelectRows]="false"
            [statusOptions]="statusOptions"
            [defaultSort]="defaultSort"
            [globalFilter]="globalFilter"
            [pageSize]="1000"
            [cellTemplates]="{ removeTestCaseTemplate: removeTestCaseTemplate }"
          >
          </lib-grid>
          <ng-template
            #removeTestCaseTemplate
            let-rowData="rowData"
            let-field="field"
          >
            <div class="pointer-cursor" (click)="removeTestCase(rowData)">
              X
            </div>
          </ng-template>
        </div>
      </div>
    </form>
  </div>
  <div class="row">
    <div id="error-message" class="col" *ngIf="errorMessage">
      {{errorMessage}}
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <button
        id="start"
        type="submit"
        class="btn btn-warning btw btn-block"
        (click)="testCaseAction()"
        [disabled]="buttonDisable"
      >
        {{ label | uppercase }}
      </button>
    </div>
  </div>
</div>
