import { Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent {

  testCaseGroupName: any;
  message:any;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  
  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal) { }

  triggerEvent() {
    this.passEntry.emit("Yes")
  }
}
