import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

export interface State {
  index: string;
  name: string;
  type: string;
  disabled: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class StatesService {
  constructor(private http: HttpClient) {}

  getStates(term: string = ''): Observable<State[]> {
    let items = getMockState();
    if (term) {
      items = items.filter(
        (x) => x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1
      );
    }
    return of(items).pipe(delay(500));
  }
}

function getMockState() {
  return [
    {
      index: '2',
      name: 'AK',
      type: 'State',
      description: 'Alaska',
      disabled: false,
    },
    {
      index: '3',
      name: 'AL',
      description: 'Alabama',
      type: 'State',
      disabled: false,
    },
    {
      index: '4',
      name: 'AR',
      description: 'Arkansas',
      type: 'State',
      disabled: false,
    },
    {
      index: '5',
      name: 'AZ',
      description: 'Arizona',
      type: 'State',
      disabled: false,
    },
    {
      index: '6',
      name: 'CA',
      description: 'California',
      type: 'State',
      disabled: false,
    },
    {
      index: '7',
      name: 'CO',
      description: 'Colorado',
      type: 'State',
      disabled: false,
    },
    {
      index: '8',
      name: 'CT',
      description: 'Connecticut',
      type: 'State',
      disabled: false,
    },
    {
      index: '9',
      name: 'DC',
      description: 'District of Columbia',
      type: 'State',
      disabled: false,
    },

    {
      index: '10',
      name: 'DE',
      description: 'Delaware',
      type: 'State',
      disabled: false,
    },
    {
      index: '11',
      name: 'FL',
      description: 'Florida',
      type: 'State',
      disabled: false,
    },
    {
      index: '12',
      name: 'GA',
      description: 'Georgia',
      type: 'State',
      disabled: false,
    },
    {
      index: '13',
      name: 'HI',
      description: 'Hawaii',
      type: 'State',
      disabled: false,
    },
    {
      index: '14',
      name: 'IA',
      description: 'Iowa',
      type: 'State',
      disabled: false,
    },
    {
      index: '15',
      name: 'ID',
      description: 'Idaho',
      type: 'State',
      disabled: false,
    },
    {
      index: '16',
      name: 'IL',
      description: 'Illinois',
      type: 'State',
      disabled: false,
    },
    {
      index: '17',
      name: 'IN',
      description: 'Indiana',
      type: 'State',
      disabled: false,
    },
    {
      index: '18',
      name: 'KS',
      description: 'Kansas',
      type: 'State',
      disabled: false,
    },
    {
      index: '19',
      name: 'KY',
      description: 'Kentucky',
      type: 'State',
      disabled: false,
    },
    {
      index: '20',
      name: 'LA',
      description: 'Louisiana',
      type: 'State',
      disabled: false,
    },
    {
      index: '21',
      name: 'MA',
      description: 'Massachusetts',
      type: 'State',
      disabled: false,
    },
    {
      index: '22',
      name: 'MD',
      description: 'Maryland',
      type: 'State',
      disabled: false,
    },
    {
      index: '23',
      name: 'ME',
      description: 'Maine',
      type: 'State',
      disabled: false,
    },
    {
      index: '24',
      name: 'MI',
      description: 'Michigan',
      type: 'State',
      disabled: false,
    },
    {
      index: '25',
      name: 'MN',
      description: 'Minnesota',
      type: 'State',
      disabled: false,
    },
    {
      index: '26',
      name: 'MO',
      description: 'Missouri',
      type: 'State',
      disabled: false,
    },
    {
      index: '27',
      name: 'MS',
      description: 'Mississippi',
      type: 'State',
      disabled: false,
    },
    {
      index: '28',
      name: 'MT',
      description: 'Montana',
      type: 'State',
      disabled: false,
    },
    {
      index: '29',
      name: 'NC',
      description: 'North Carolina',
      type: 'State',
      disabled: false,
    },
    {
      index: '30',
      name: 'ND',
      description: 'North Dakota',
      type: 'State',
      disabled: false,
    },
    {
      index: '31',
      name: 'NE',
      description: 'Nebraska',
      type: 'State',
      disabled: false,
    },
    {
      index: '32',
      name: 'NH',
      description: 'New Hampshire',
      type: 'State',
      disabled: false,
    },
    {
      index: '33',
      name: 'NJ',
      description: 'New Jersey',
      type: 'State',
      disabled: false,
    },
    {
      index: '34',
      name: 'NM',
      description: 'New Mexico',
      type: 'State',
      disabled: false,
    },
    {
      index: '35',
      name: 'NV',
      description: 'Nevada',
      type: 'State',
      disabled: false,
    },
    {
      index: '36',
      name: 'NY',
      description: 'New York',
      type: 'State',
      disabled: false,
    },
    {
      index: '37',
      name: 'OH',
      description: 'Ohio',
      type: 'State',
      disabled: false,
    },
    {
      index: '38',
      name: 'OK',
      description: 'Oklahoma',
      type: 'State',
      disabled: false,
    },
    {
      index: '39',
      name: 'OR',
      description: 'Oregon',
      type: 'State',
      disabled: false,
    },
    {
      index: '40',
      name: 'PA',
      description: 'Pennsylvania',
      type: 'State',
      disabled: false,
    },
    {
      index: '41',
      name: 'RI',
      description: 'Rhode Island',
      type: 'State',
      disabled: false,
    },
    {
      index: '42',
      name: 'SC',
      description: 'South Carolina',
      type: 'State',
      disabled: false,
    },
    {
      index: '43',
      name: 'SD',
      description: 'South Dakota',
      type: 'State',
      disabled: false,
    },
    {
      index: '44',
      name: 'TN',
      description: 'Tennessee',
      type: 'State',
      disabled: false,
    },
    {
      index: '45',
      name: 'TX',
      description: 'Texas',
      type: 'State',
      disabled: false,
    },
    {
      index: '46',
      name: 'UT',
      description: 'Utah',
      type: 'State',
      disabled: false,
    },
    {
      index: '47',
      name: 'VA',
      description: 'Virginia',
      type: 'State',
      disabled: false,
    },
    {
      index: '48',
      name: 'VT',
      description: 'Vermont',
      type: 'State',
      disabled: false,
    },
    {
      index: '49',
      name: 'WA',
      description: 'Washington',
      type: 'State',
      disabled: false,
    },
    {
      index: '50',
      name: 'WI',
      description: 'Wisconsin',
      type: 'State',
      disabled: false,
    },
    {
      index: '51',
      name: 'WV',
      description: 'West Virginia',
      type: 'State',
      disabled: false,
    },
    {
      index: '52',
      name: 'WY',
      description: 'Wyoming',
      type: 'State',
      disabled: false,
    },
  ];
}
