
<div class="modal-body">
  <div>
    <h1 class="modal-title pull-left heading">Select One or More States</h1>
    <p class="modal-label">
      Begin by filtering test case results by state(s).
    </p>
  </div>
  <div>
    <app-multiselect-dropdown
      (onSelect)="statesSelected($event)"
    ></app-multiselect-dropdown>
  </div>
  <div class="button-padding">
    <button
      id="start"
      type="button"
      class="btn btn-warning btw"
      [disabled]="isEmpty"
      (click)="saveState()"
      (click)="activeModal.dismiss()"
    >
      Start
    </button>
  </div>
</div>
