
<ng-select #Selecter
  id="states"
  class="state-multiselect"
  [items]="states"
  [multiple]="true"
  bindLabel="name"
  [selectableGroup]="false"
  [closeOnSelect]="false"
  [clearSearchOnAdd]="true"
  [selectableGroupAsModel]="false"
  bindValue="index"
  dropdownPosition="bottom"
  [(ngModel)]="selectedState"
  placeholder="States"
  (add)="stateSelection()"
  (remove)="stateSelection()" 
  (clear)="stateSelection()"
  [ngClass]="{ 'invalid-select': isEmpty, 'max-select': maxSelect}"
  [maxSelectedItems]= "maxStateLimit"
>
  <ng-template
    ng-optgroup-tmp
    let-item="item"
    let-item$="item$"
    let-index="index"
  >
    <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" />
    All State
  </ng-template>
  <ng-template
    ng-option-tmp
    let-item="item"
    let-item$="item$"
    let-index="index"
  >
  <div title="{{item.description}}">
    <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" />
    {{item.name}}
  </div>
    
  </ng-template>
 </ng-select> 
<div class="multiselect-warning" *ngIf="maxSelect">* Maximum Selection Reached</div>
