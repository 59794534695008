import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridComponent } from '@uw-verisk/grid';
import { TestcasesService } from 'src/app/services/testcases.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-groups-details',
  templateUrl: './groups-details.component.html',
  styleUrls: ['./groups-details.component.css']
})
export class GroupsDetailsComponent implements OnInit {
  noOfTestCasesSelected;
  cols: any = [
    {
      field: 'testCaseId',
      header: 'Test Case ID',
      filter: false,
      sortable: true,
      qspassthrough: true,
      clickeable: true,
    },
    {
      field: 'noOfPolicies',
      header: 'Policy Count',
      sortable: true,
      qspassthrough: true,
    },
    {
      field: 'state',
      header: 'State',
      sortable: true,
      fullWidth: true,
      qspassthrough: true,
    },
    {
      field: 'noOfDrivers',
      header: 'Driver Count',
      sortable: true,
      qspassthrough: true,
    },
    {
      field: 'noOfVehicles',
      header: 'Vehicle Count',
      sortable: true,
      qspassthrough: true,
    },
    {
      field: 'lapseInCoverage',
      header: 'Lapse',
      sortable: true,
      qspassthrough: true,
    },
    {
      field: 'multipleCarriers',
      header: 'Multiple Carriers',
      sortable: false,
      qspassthrough: true,
    },
    {
      field: 'delete',
      header: '',
      cellTemplate: 'groupDeleteTemplate',
      sortable: false,
      clickeable: true,
      qspassthrough: true,
    }
  ];
  buttonConfig = [
    {
      label: `Remove Test Case(s)`,
      action: 'remove',
      bulkAction: true,
      btnClass: 'btn-primary',
      btnIcon: 'fa',
      resourcename: 'export:edit',
    },
  ];
  @ViewChild('groupDetailsGrid') groupDetailsGridRef: GridComponent;
  name: any;
  description: any;
  data: any = [];
  globalFilter = '';
  groupUniqueId: any;
  uniqueId;
  loading;
  requestBody;
  dataKey: any = 'testCaseId';
  defaultSort = { field: 'dateAdded', sortMode: -1 };
  statusOptions: any = { OPEN: 'label-success', EXPIRED: 'label-warning' };
  constructor(private groupsService : TestcasesService,  private router: Router,  private modalService: NgbModal) { }

  ngOnInit(): void {
    this.groupsService.getSpinnerObserver().subscribe((status) => {
      this.loading = status === 'start';
    })
    this.groupUniqueId = localStorage.getItem('groupUniqueIds');
    this.name = localStorage.getItem('name');
    this.description = localStorage.getItem('description');
    this.groupsService.getTestCaseGroupDetails(this.groupUniqueId).subscribe((data) =>
    {
      this.data = data;
    } );
  }
  groupCellClick(event)
  {
    let uniqueIds = event.rowData.uniqueId;
    localStorage.setItem('uniqueIds', uniqueIds);
    this.router.navigateByUrl('/CoverageVerifier/policySearch');
  }
  selectedRows(event) 
  {
   this.uniqueId = event.selectedRowData.map(x => x.uniqueId);
   this.requestBodyGenerator();
  }
  remove(rowData) {
    this.uniqueId = [rowData.uniqueId];
    this.requestBodyGenerator();
    
  }
  requestBodyGenerator() {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, {
      centered: true,
      backdrop: 'static',
      backdropClass: 'backdrop-class',
    }); 
    
    modalRef.componentInstance.message = "Are you sure?";
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      if(receivedEntry === "Yes")
      {
        
        this.requestBody = {
          "testCaseGroupUniqueId" : this.groupUniqueId,
          "testCaseUniqueIds" : this.uniqueId
        }
       this.groupsService.removeTestCasesFromGroup(this.requestBody).subscribe(()=> {
        this.modalService.dismissAll();
         this.groupsService.getTestCaseGroupDetails(this.groupUniqueId).subscribe((data) =>
        {
          this.data = data;
        } );});
       this.groupDetailsGridRef.selectedRowData = [];
    
      }}
      );
    
  }
  noOfTestCaseSelection(event) {
    this.noOfTestCasesSelected = event.length;
    this.buttonConfig.forEach(x => x.label= `Remove ${this.noOfTestCasesSelected} Test Case(s)`);
  }
  testCaseGroups() {
    this.router.navigateByUrl('/CoverageVerifier/testCaseGroups');
  }
}
