
<div class="page-wrapper">
  <div class="testcase-grid">
    <h1>Test Cases</h1>
  </div>
  <div>
    <div [ngClass]="{ 'search-result-grid-disable': !isLoaded || loading }">
      <strong>{{ data.length | number : "1.0" }} results found</strong>
    </div>
    <lib-spinner
      [isBusy]="loading"
      [backdrop]="false"
      [type]="'center'"
      [small]="true"
    ></lib-spinner>
    <lib-grid
      #explorerGrid
      class="explorerDataGrid"
      [columns]="cols"
      [data]="data"
      [dataKey]="dataKey"
      [selectableRows]="false"
      [expandableRows]="false"
      [exportData]="false"
      [multiSelectRows]="true"
      [statusOptions]="statusOptions"
      [defaultSort]="defaultSort"
      [globalFilter]="globalFilter"
      [pageSize]="pageSize"
      [cellTemplates]="{ testcaseGroupTemplate: testcaseGroupTemplate }"
      (CellClick)="cellClick($event)"
      (SelectedRows)="selectedRows($event)"
      [btnConfig]="buttonConfig"
      [ngClass]="{ 'search-result-grid-disable': !isLoaded || loading }"
    >
    </lib-grid>
    <ng-template #testcaseGroupTemplate let-rowData="rowData" let-field="field">
      <div *ngFor="let group of rowData.groups" (click)="groupClick(group)">
        <a>{{ group.name }}</a>
      </div>
    </ng-template>
    <div
      class="alert alert-info search-result-grid-disable"
      [ngClass]="{ 'search-result-no-records': showNoRecords }"
    >
      No records to display.
    </div>
  </div>
</div>
