import { Component, OnChanges, OnInit } from '@angular/core';
import { PolicyDetailsService } from 'src/app/services/policy-details.service';

@Component({
  selector: 'app-policy-search',
  templateUrl: './policy-search.component.html',
  styleUrls: ['./policy-search.component.css'],
})
export class PolicySearchComponent implements OnInit, OnChanges {
  active = 1;
  tabData: any;
  homeExtension: any;
  officeExtension: any;
  mobileExtension: any;
  policyDetails: any;
  policy: any;
  vehicles: any[] = [];
  subjects: any[] = [];
  body: any;
  isLoaded:any;
  loading:any=true;
  showNoRecords:any;
  public subjectSelected: any;
  public vehicleSelected: any;
  constructor(private policyService: PolicyDetailsService) {}

  ngOnInit(): void {
    this.body = localStorage.getItem('uniqueIds');
    this.policyService
      .getPolicyDetailValue(this.body)
      .subscribe((data) => {
        if (data && data.policies.length>0) {
          this.policyService.policies.next(data);
          this.policy = data;
          this.policyDetails = this.policy.policies[0];
          this.isLoaded=true;
          this.showNoRecords=false;
          this.loading=false;
        }
        else{
          this.isLoaded=false;
          this.showNoRecords=true;
          this.loading=false;
        }
      });

    this.policyService.transactionDetails.subscribe((data) => {
      if (Object.keys(data).length > 0) {
        this.tabData = data;
        this.homeExtension = this.tabData?.information?.homePhone.substring(12);
        this.officeExtension = this.tabData?.information?.officePhone.substring(12);
        this.mobileExtension = this.tabData?.information?.mobilePhone.substring(12);
        this.subjectSelected = this.tabData.subjects[0];
        this.vehicleSelected = this.tabData.vehicles[0];
      } 
    });

    this.policyService.policyDetails.subscribe((data) => {
      if (data) {
        this.policyDetails = data;
      }
    });  
  }
  ngOnChanges(state: any): void {
    this.subjectSelected = this.tabData.subjects[0];
  }
}
