import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAppConfig } from './models/config';
import { OAuthModuleConfig } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public configuration: any;

  constructor(private httpClient: HttpClient,private oauthconfig :OAuthModuleConfig) {}

  async setConfig(): Promise<IAppConfig> {
    await this.httpClient
     .get<IAppConfig>('../assets/jsons/config.json')
     .toPromise()
     .then((config) => {
       this.configuration = config ;
     });
     return this.configuration;
 }

  readConfig(): IAppConfig {
    return this.configuration;
  }

 
}