import * as PolicyDetailActions from '../actions/test-case-explorer-actions';
import { TestCaseExplorerState } from '../model/test-case-explorer-interface';

export function policyInfoReducer(state: TestCaseExplorerState, action):TestCaseExplorerState {
    switch (action.type) {
      case PolicyDetailActions.TEST_CASE_FILTERS:{
        return{               
            ...state,
            filter:action.filter
        }
    }
      default:
          return {...state};
      }
  }