
<div class="modal-header">
  <h3 class="modal-title ml-5" id="modal-title">Revoke User Access</h3>
  <button
    type="button"
    class="close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="modal.dismiss(0)"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="errorMessage" class="ml-5 mr-5 alert alert-danger user-exists">
    <i class="va-icon-info_outline mr-2"></i
    ><span class="mt-1">{{ errorMessage }}</span>
  </div>
  <p class="content">
    Are you sure you want to revoke
    <span class="highlight-content">{{ user?.emailId }}</span> access for
    <span class="highlight-content">{{ product?.productDescription }}</span>
    Product?
  </p>

  <div class="modal-footer d-flex justify-content-center px-0 mt-5">
    <button
      type="submit"
      ngbAutofocus
      class="btn btn-primary btn-lg delete mt-4 mb-3 mx-0"
      (click)="onSubmit()"
    >
      Delete
    </button>
  </div>
</div>
