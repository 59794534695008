import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TestcasesService } from 'src/app/services/testcases.service';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { GroupsModalComponent } from './groups-modal/groups-modal.component';

@Component({
  selector: 'app-test-case-groups',
  templateUrl: './test-case-groups.component.html',
  styleUrls: ['./test-case-groups.component.css']
})
export class TestCaseGroupsComponent implements OnInit {
  cols: any = [
    {
      field: 'name',
      header: 'Group Name',
      filter: false,
      sortable: true,
      qspassthrough: true,
      clickeable: true,
    },
    {
      field: 'description',
      header: 'Description',
      sortable: true,
      qspassthrough: true,
    },
    {
      field: 'totalTestCases',
      header: 'Total Test Cases',
      sortable: true,
      fullWidth: true,
      qspassthrough: true,
    },
    {
      field: 'createdBy',
      header: 'Created By',
      sortable: true,
      qspassthrough: true,
    },
    {
      field: 'createdDate',
      header: 'Creation Date',
      sortable: true,
      qspassthrough: true,
    },
    {
      field: 'modifedBy',
      header: 'Last Edit',
      sortable: true,
      qspassthrough: true,
    },
    {
      field: 'modifiedDate',
      header: 'Update Date',
      sortable: true,
      qspassthrough: true,
    },
    {
    field: 'edit',
    header: '',
    cellTemplate: 'groupEditDeleteTemplate',
    sortable: false,
    qspassthrough: true,
    }
  ];
  data: any=[];
  globalFilter = '';
  loading;
  dataKey: any = 'testCaseGroupUniqueId';
  defaultSort = { field: 'dateAdded', sortMode: -1 };
  statusOptions: any = { OPEN: 'label-success', EXPIRED: 'label-warning' };
  constructor(private groupsService : TestcasesService,  private router: Router,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.groupsService.getSpinnerObserver().subscribe((status) => {
      this.loading = status === 'start';
    });
    this.groupsService.getAllTestCaseGroups().subscribe((data) =>
    {
      this.data = data;
    } );
  }
  groupCellClick(event)
  {
    let name = event.rowData.name;
    let description = event.rowData.description;
    localStorage.setItem('groupUniqueIds', event.rowData.testCaseGroupUniqueId);
    localStorage.setItem('name', name);
    localStorage.setItem('description', description);
    this.router.navigateByUrl('/CoverageVerifier/testCaseGroupsDetails');
  }
  deleteGroup(data)
  {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, {
      centered: true,
      backdrop: 'static',
      backdropClass: 'backdrop-class',
    });
    modalRef.componentInstance.message = `Are you sure you want to delete Test Case Group ${data.name}?`;
    modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
      if(receivedEntry === "Yes")
      {
        
      this.groupsService.deleteTestCaseGroup(data.testCaseGroupUniqueId).subscribe(() => {
        this.modalService.dismissAll();
        this.groupsService.getAllTestCaseGroups().subscribe((data) =>
        {
          this.data = data;
        } );
      });
    
      }}
      );
    
    
  }
  editGroup(data)
  {
    const modalRef = this.modalService.open(GroupsModalComponent, {
      centered: true,
      backdrop: 'static',
      backdropClass: 'backdrop-class',
    });
    modalRef.componentInstance.label = "Edit Test Case Group";
    modalRef.componentInstance.name = data.name;
    modalRef.componentInstance.description = data.description;
    modalRef.componentInstance.uniqueId = data.testCaseGroupUniqueId;
    modalRef.result.then((modalClose) => {
      this.groupsService.getAllTestCaseGroups().subscribe((data) =>
    {
      this.data = data;
    } );
    });  
  }

  
}
