import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  public filterData = new BehaviorSubject<any>({});

  public applyFilter = new BehaviorSubject<boolean>(false);
  apiUrl: string;
  
  constructor(private http: HttpClient,private appService: AppService) {}

  public getFilterValue(body): Observable<any> {
    this.apiUrl = this.appService.configuration.apiUrl;
    let url = `${this.apiUrl}/CV/DisplayFilterValues`;
    return this.http.post(url, body) as Observable<any>;
  }

}
