import { Injectable} from '@angular/core';
import { Actions, createEffect, ofType} from  '@ngrx/effects';
import { map, exhaustMap, catchError} from  'rxjs/operators';
import { of } from 'rxjs';
import * as policyDetailActions from '../actions/test-case-explorer-actions';
import { testCaseFilters, testCaseFiltersSuccess } from '../actions/filter-value-action';
import { FiltersService } from 'src/app/services/filters.service';

@Injectable()
export class TestCasefiltersEffects{
body:any = {};
    constructor(private actions$: Actions, private service: FiltersService){}

    FiltersValue$= createEffect(() =>
        this.actions$.pipe(
            ofType(testCaseFilters),
            exhaustMap(action =>
             this.service.getFilterValue(this.body).pipe(
                    map(response =>{
                        
                        return testCaseFiltersSuccess(response)
                    }),
                    catchError((error:any) => of(policyDetailActions.testCaseError(error)))
                ) 
            )
        )

    );
}