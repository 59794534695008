import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppService } from '../app.service';

@Injectable({
  providedIn: 'root',
})
export class PolicyDetailsService {
  apiUrl: string;

  public transactionDetails = new BehaviorSubject<any>({}); 
  public policyDetails = new BehaviorSubject<any>({});
  public policies = new BehaviorSubject<any>({});
  constructor(private http: HttpClient, private appService: AppService) {}
  public getPolicyDetailValue(body): Observable<any> {
    this.apiUrl = this.appService.configuration.apiUrl;
    let url = `${this.apiUrl}/CV/TestCaseDetail/${body}`;
    return this.http.post(url, body) as Observable<any>;
  }
}
