import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from '@uw-verisk/common-ui';
import { TestcasesService } from 'src/app/services/testcases.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  errorMessage:string="An unexpected error has occured - Please refresh the page";
  errorCount: number=0;
  constructor(private toastService: ToastService, private testService: TestcasesService) {}
  intercept(request, next) {
    // Handle response
    return next.handle(request).pipe(
      catchError(error => {
        //handle specific errors
        this.testService.requestReset();
        let errorIndex = error.status.toString();
        if ((errorIndex.charAt(0) === '4' || errorIndex.charAt(0) === '5' )&&this.errorCount==0) {
            this.toastService.error(this.errorMessage);
            this.errorCount++;
        }
        return throwError(this.errorMessage || error);
      })
    );
}
}