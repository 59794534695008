
<div class="modal-header">
  <h3 class="modal-title ml-5" id="modal-title">Invite New User</h3>
  <button
    type="button"
    class="close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="modal.dismiss(0)"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div>
  <p class="mt-3 mr-5 pl-5">
    Add a user to the portal via an email invitation. Domains are restricted to
    the application
  </p>
  <div
    *ngIf="errorMessage"
    class="mt-3 mr-5 pl-5 alert alert-danger user-exists"
  >
    <i class="va-icon-info_outline mr-2"></i
    ><span class="mt-1">{{ errorMessage }}</span>
  </div>

  <form
    [formGroup]="inviteUserForm"
    (ngSubmit)="onSubmit()"
    class="mt-3 mr-5 pl-5"
  >
    <div class="invite-modal-body">
      <div class="row">
        <div
          [ngClass]="{
            'is-invalid':
              submitted &&
              (form.name.errors || checkEmail == null || maxLengthEmail)
          }"
          class="col-6 name-wrapper"
        >
          <input
            [ngClass]="{
              'is-invalid':
                submitted &&
                (form.name.errors || checkEmail == null || maxLengthEmail)
            }"
            formControlName="name"
            type="text"
            class="form-control form-element-no-border pl-0 mt-4"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Email Username"
          />
        </div>

        <div class="col-6">
          <ng-select class="select-domain pb-0" formControlName="email">
            <ng-option *ngFor="let email of emailDomain" [value]="email">{{
              email
            }}</ng-option>
          </ng-select>
        </div>
      </div>

      <div
        class="row"
        *ngIf="
          submitted &&
          (form.name.errors || checkEmail == null || maxLengthEmail)
        "
      >
        <div class="role-error name-error invalid-feedback ml-3">
          <div *ngIf="form.name.errors && form.name.errors.required">
            * Required
          </div>
          <div
            *ngIf="
              (form.name.errors &&
                !form.name.errors.required &&
                form.name.errors.pattern) ||
              (!inviteUserForm.invalid && checkEmail == null)
            "
          >
            * Special characters not allowed
          </div>
          <div *ngIf="checkEmail && maxLengthEmail">
            * Email Id should not exceeds more than 100 characters
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="col-12"
          [ngClass]="{ 'is-invalid': submitted && form.role.errors }"
        >
          <ng-select
            dropdownPosition="bottom"
            [ngClass]="{ 'is-invalid': submitted && form.role.errors }"
            class="select-permissions-group pb-0"
            formControlName="role"
          >
            <ng-option value="">Select Permissions Group</ng-option>
            <ng-option
              *ngFor="let role of rolePermissions"
              [value]="role.authorizationGroupIdentity"
            >
              {{ role.groupName }}</ng-option
            >
          </ng-select>

          <div *ngIf="submitted && form.role.errors" class="invalid-feedback">
            <div *ngIf="form.role.errors && form.role.errors.required">
              * Required
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer d-flex justify-content-center px-0 mt-5">
      <button
        type="submit"
        ngbAutofocus
        class="btn btn-primary btn-lg invite mt-4 mb-3 mx-0"
      >
        SEND INVITATION
      </button>
    </div>
  </form>
</div>
