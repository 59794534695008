import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  constructor(private dashboardService: DashboardService) {}
  public data:any[] = [
]
loading:boolean=true;

  ngOnInit() {
    this.dashboardService
      .getProducts({ UserId: 'userid' })
      .subscribe((data) => {
        if(data){
        this.loading=false
        this.data=data;
        this.dashboardService.productsData.next(data);
        }
      });
  }
}
