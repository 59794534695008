
<div class="page-wrapper">
  <div class="col-7 float-left p-l-r-0">
    <h1>User Management</h1>
  </div>
  <div class="col-5 float-right p-l-r-0">
    <button
      type="button"
      class="btn btn-primary btn-lg invite float-right"
      (click)="invite()"
    >
      INVITE NEW USER
    </button>
  </div>

  <div class="users card-wrapper">
    <lib-grid
      class="usermanagementGrid"
      [columns]="cols"
      [data]="users"
      [dataKey]="dataKey"
      (filtersReseted)="filtersReseted()"
      [globalFilter]="globalFilter"
      [selectableRows]="false"
      [multiSelectRows]="true"
      [expandableRows]="false"
      [statusOptions]="statusOptions"
      [cellTemplates]="{
        actionsTemplate: actionsTemplate,
        statusTemplate: statusTemplate
      }"
    >
    </lib-grid>

    <ng-template #actionsTemplate let-rowData="rowData" let-field="field">
      <div *ngIf="userId != rowData.userIdentity">
        <a class="link"
          ><i class="va-icon-mode_edit" (click)="editUser(rowData)"></i
        ></a>
        <a class="link"
          ><i class="va-icon-delete" (click)="deleteUser(rowData)"></i
        ></a>
      </div>
    </ng-template>
    <ng-template #statusTemplate let-rowData="rowData" let-field="field">
      <div class="status-template">
        <div *ngIf="rowData.statusColumn == 'Active'">
          <i class="status-template-color" class="va-icon-check_circle"></i>
          {{ rowData.statusColumn }}
        </div>
        <div *ngIf="rowData.statusColumn == 'InActive'">
          -- {{ rowData.statusColumn }}
        </div>
      </div>
    </ng-template>
  </div>
</div>
