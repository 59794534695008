import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { ToastService } from '@uw-verisk/common-ui';
import { UserInformation } from 'src/app/models/userinformation';
import { TestcasesService } from 'src/app/services/testcases.service';
import { DeleteComponent } from './delete/delete.component';
import { EditComponent } from './edit/edit.component';
import { InviteComponent } from './invite/invite.component';

@Component({
  selector: 'app-usermanagement',
  templateUrl: './usermanagement.component.html',
  styleUrls: ['./usermanagement.component.css']
})
export class UsermanagementComponent implements OnInit {

  users: any/** users list */
  dataKey = "emailId";
  type: string;
  cols = {};
  response: any;
  customerId: number;
  userId: number;
  productCode: string;
  productId: number;
  permissions: any;

  constructor(private modalService: NgbModal, private route: ActivatedRoute, public pipeProvider: DatePipe, private toastservice :ToastService, private service: TestcasesService,
    private store: Store<AppState>) {
    this.store.select(state => state.userPreferences).subscribe((data) => {
      this.response = data;
      if (data) {
        this.customerId = data.customerIdentity;
        this.userId = data.userIdentity;
        this.permissions = data.permissions;
      }
    });
  }

  globalFilter = '';
  statusOptions = {};
  dataFormatter = {
    pipeArgs: ['MM/dd/yyyy', 'UTC'],
    pipeProvider: this.pipeProvider
  }
  ngOnInit(): void {

    this.users = [];
    this.type = "";
    this.dataKey = "emailId";
    this.cols = [
      { header: 'First Name', field: 'firstName', sortable: true, qspassthrough: true },
      { header: 'Last Name', field: 'lastName', sortable: true, qspassthrough: true },
      { header: 'Email', field: 'emailId', sortable: false, filter: true, qspassthrough: true },
      { header: 'Permission Group', field: 'role', filter: true, sortable: false, qspassthrough: true },
      { header: 'Status', cellTemplate: 'statusTemplate', filter: true, field: 'statusColumn' },
      { header: 'Date', field: 'dateAdded', dataFormatter: this.dataFormatter, sortable: true, isDate: true, filter: true, qspassthrough: true },
      { header: '', cellTemplate: 'actionsTemplate' }
    ];
    this.listUsers();

  }

  listUsers() {
    this.productCode = 'CV';
    let permission = this.permissions.find(item => item.productCode == this.productCode);  
    this.productId = permission.productIdentity;

  this.service.listUsers('CV').subscribe(res => {
    this.users = res;
    this.convertFields();
  });
  
}

  filtersReseted() {
    this.globalFilter = '';
  }

  invite() {
    const modalRef = this.modalService.open(InviteComponent, {
      centered: true,
      windowClass: "user-management"
    });

    modalRef.componentInstance.productId = this.productId;
    modalRef.componentInstance.companyId = this.customerId;

    modalRef.result.then((result) => {
    }, (reason) => {
      if (reason) {
        this.toastservice.success('New user invitation sent.');
        this.listUsers();
      }
    });
  }


  editUser(record: UserInformation) {
    const modalRef = this.modalService.open(EditComponent, {
      centered: true,
      windowClass: "user-edit-management"
    });

    modalRef.componentInstance.user = record;
    modalRef.componentInstance.productId = this.productId;
    modalRef.result.then((result) => {
    }, (response) => {
      if (response && "code" in response) {
        this.toastservice.success ('Permission group has been updated successfully');
        this.ngOnInit();
      }
    });
  }

  deleteUser(record: UserInformation) {
    const modalRef = this.modalService.open(DeleteComponent, {
      centered: true,
      size: 'lg',
      windowClass: "user-management"

    });
    modalRef.componentInstance.user = record;
    modalRef.result.then((result) => {
    }, (response) => {
      if (response && "code" in response) {
        this.toastservice.success('Permission group has been removed successfully');
        this.listUsers();
      }
    });
  }

  convertFields() {
    this.users.forEach(item => {
      item.statusColumn = item.status ? 'Active' : 'InActive';
      if (item.authorizationGroups.length > 0) {
        let groupName = item.authorizationGroups[0].authorizationGroupName;
        item.role = this.permissionGroup(groupName);
      }
      else {
        item.role = ''
      }
    });
  }

  permissionGroup(groupName){
    if(!groupName) return '';
    let gnArray = groupName?.split('-');
    if(!gnArray.length || gnArray.length < 3) return '';
    let permissionGroupArray = gnArray[gnArray.length - 1].split(' ');
    if (permissionGroupArray && permissionGroupArray.length)
      return permissionGroupArray[0];
    else
      return '';
  }

}
