
<app-header></app-header>
<app-toasts></app-toasts>

<div class="container-fluid main-content">
  <ng-sidebar-container class="uw-sidebar-container d-flex flex-wrap">
    <!-- A sidebar -->
    <ng-sidebar class="uw-sidebar" [(opened)]="_opened" [mode]="_MODES" >
      <app-left-nav></app-left-nav>
    </ng-sidebar>

    <!-- Page content -->
    <div
      class="uw-sidebar-content w-100"
      style="margin-bottom: 20px;"
      ng-sidebar-content
    >
      <router-outlet></router-outlet>
    </div>
  </ng-sidebar-container>
</div>
<app-footer></app-footer>
