
<div class="modal-header">
  <h3 class="modal-title ml-5" id="modal-title">Edit User</h3>
  <button
    type="button"
    class="close"
    aria-label="Close button"
    aria-describedby="modal-title"
    (click)="modal.dismiss(0)"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" class="user-edit-modal">
  <div *ngIf="errorMessage" class="ml-5 mr-5 alert alert-danger user-exists">
    <i class="va-icon-info_outline mr-2"></i
    ><span class="mt-1">{{ errorMessage }}</span>
  </div>
  <form
    [formGroup]="editUserForm"
    (ngSubmit)="onSubmit()"
    class="pl-5 mt-5 mr-5"
  >
    <div class="row">
      <div class="col name-wrapper px-0 mx-3">
        <input
          formControlName="email"
          type="text"
          class="form-control form-element-no-border"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
        />
      </div>
    </div>

    <div class="row">
      <div
        class="col"
        [ngClass]="{ 'is-invalid': submitted && form.role.errors }"
      >
        <ng-select
          dropdownPosition="bottom"
          [ngClass]="{ 'is-invalid': submitted && form.role.errors }"
          (click)="poulatePermissions()"
          class="role select-permissions-group mt-3 pb-0"
          formControlName="role"
        >
          <ng-option value="">Select Permission Group</ng-option>
          <ng-option
            *ngFor="let role of rolePermissions"
            [value]="role.authorizationGroupIdentity"
            >{{ role.groupName }}</ng-option
          >
        </ng-select>
        <div *ngIf="submitted && form.role.errors" class="invalid-feedback">
          <div *ngIf="form.role.errors.required">* Required</div>
        </div>
      </div>

      <div
        class="col-5"
        [ngClass]="{ 'is-invalid': submitted && form.status.errors }"
      >
        <ng-select
          [ngClass]="{ 'is-invalid': submitted && form.status.errors }"
          class="select-permissions-group mt-3 pb-0"
          formControlName="status"
        >
          <ng-option value="">Select Status</ng-option>
          <ng-option
            *ngFor="let statusColumn of statuses"
            [value]="statusColumn"
            >{{ statusColumn }}</ng-option
          >
        </ng-select>
        <div *ngIf="submitted && form.status.errors" class="invalid-feedback">
          <div *ngIf="form.status.errors.required">* Required</div>
        </div>
      </div>
    </div>

    <div class="modal-footer d-flex justify-content-center px-0 mt-5">
      <button
        type="submit"
        ngbAutofocus
        class="btn btn-primary btn-lg update mt-4 mb-3 mx-0"
      >
        UPDATE
      </button>
    </div>
  </form>
</div>
