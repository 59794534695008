import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TestcasesService } from 'src/app/services/testcases.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {
  @Input() public user;
  @Input() public productId;
  rolePermissions :any;
  selectedRole = null; 
  statuses :any =['Active', 'InActive'];
  submitted :boolean;
  editUserForm: any;
  errorMessage: any;

  constructor(public modal: NgbActiveModal, private fb: FormBuilder, private service : TestcasesService) { }

  ngOnInit(): void {
    this.submitted = false;
    this.editUserForm = this.fb.group({
      email: [{ value : this.user['emailId'], disabled : true }, Validators.required],
      role: ['', Validators.required],
      status: [{value : this.user['statusColumn'], disabled : true }, Validators.required]
    });

    this.service.getPermissionGroup(this.user.product.productIdentity).subscribe(res =>{
      Object.entries(res.authorizationGroups).forEach(([key, value]) => {
        let groupName = ((value['authorizationGroupName']).split('-')[2]).split(' ')[0];
        value['groupName'] = groupName;
        if( this.user['role'] == groupName ) {
          this.selectedRole = value['authorizationGroupIdentity'];
        }
      } );
      
      this.rolePermissions = res.authorizationGroups;
      this.editUserForm.controls['role'].setValue(this.selectedRole, {onlySelf: true});
    })


  }

  poulatePermissions() {
    Object.entries(this.rolePermissions).forEach(([key, value]) => {
      let groupName = ((value['authorizationGroupName']).split('-')[2]).split(' ')[0];
      value['groupName'] = groupName;
      if( this.user['role'] == groupName ) {
        delete this.rolePermissions[key]
        this.selectedRole = value['authorizationGroupIdentity'];
      }
    } );
    this.rolePermissions = this.rolePermissions.filter(function(e){ return e != null;});
  }

  get form() { return this.editUserForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.editUserForm.invalid) {
      return;
    }
    let userInfo = {
      emailId: this.user.emailId,
      oldAuthGroupId: this.user.authorizationGroups[0].authorizationGroupIdentity,
      newAuthGroupId: this.editUserForm.get('role').value,
      productIdentity: this.productId
    }
    this.service.editUser(userInfo).subscribe(response =>{
      response['email'] = this.user.emailId;
      if(response && 'errorCode' in response && response['errorCode'] == 'UM1006') {
        this.errorMessage = "User "+ this.user.emailId +" does not belong to your company";
      }else if(response && 'errorCode' in response && response['errorCode'] == 'UM1002') {
        this.errorMessage ="Invalid authorization group for the customer.";
      }else{
        this.errorMessage="";
        this.modal.dismiss(response);
      }
    })
  }

}
