import { IAppConfig } from '@uw-verisk/common-ui';
import { Component, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { TestcasesService } from 'src/app/services/testcases.service';
import { CookieService } from 'ngx-cookie-service';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.css'],
})
export class LeftNavComponent implements OnInit {
  appConfig: IAppConfig;
  viewByDropdown: IAppConfig;
  selectedItem = {};
  viewBy = {};
  navHeader: string = 'Product Menu';
  type: any; /** type of policy from query string */
  policyTypes: any; /** policy types */
  multiple = true;
  hideNav: boolean = false;
  selectedStates: string = '';
  IsExplorer: boolean = false;
  IsPolicy: boolean = false;
  IsHome: boolean = false;
  IsGroup: boolean = false;
  product: any[] = [];
  productName: string = '';
  selectedType: string = 'Coverage Verifier';
  showMore: boolean = false;
  showList: boolean = false;

  constructor(
    private router: Router,
    private cookie: CookieService,
    private testService: TestcasesService,
    private productService: DashboardService
  ) {
    
    this.policyTypes = {
      CoverageVerifier: 'Coverage Verifier',
    };
  }

  ngOnInit(): void {
    this.router.events.subscribe((val) => {
      if (val instanceof RoutesRecognized) {
        switch (val.state.url) {
          case '/':
            this.IsHome = true;
            this.IsExplorer = false;
            this.IsPolicy = false;
            this.selectedItem = {label: null};
            this.IsGroup = false;
            this.appConfig.label = 'Select Product'
            break;
          case '/CoverageVerifier/testcaseExplorer':
            this.IsExplorer = true;
            this.IsPolicy = false;
            this.IsHome = false;
            this.viewBy = {label: 'Test Cases'};
            this.appConfig.label = 'Product'
            this.selectedItem = {label: 'Coverage Verifier'}
            this.IsGroup = false;
            break;
          case '/CoverageVerifier/policySearch':
            this.IsPolicy = true;
            this.IsExplorer = false;
            this.IsHome = false;
            this.IsGroup = false;
            break;
          case '/CoverageVerifier/testCaseGroups':
            this.IsGroup = true;
            this.IsExplorer = false;
            this.IsPolicy = false;
            this.IsHome = false;
            this.selectedItem = {label: 'Coverage Verifier'}
            this.viewBy = {label: 'Test Case Groups'};
            break;
          case '/CoverageVerifier/testCaseGroupsDetails':
            this.IsGroup = true;
            this.IsExplorer = false;
            this.IsPolicy = false;
            this.selectedItem = {label: 'Coverage Verifier'}
            this.IsHome = false;
            this.viewBy = {label: 'Test Case Groups'};
            break;
          case '/userManagement':
              this.IsHome = true;
              this.IsExplorer = false;
              this.IsPolicy = false;          
              this.IsGroup = false;
              this.appConfig.label = 'Product';
              this.selectedItem = {label: 'Coverage Verifier'};
              break;
          default:
            this.IsExplorer = false;
            this.IsPolicy = false;
            this.IsHome = false;
            this.IsGroup = false;
            break;
        }
      }
    });
    let menuItems = [];

    this.product = [{ product: 'Coverage Verifier' }];
    this.appConfig = {
      label: 'Select Product',
      placeholder: '',
      menuItems: [{
                name: 'Coverage Verifier',
                isActive: false,
                route: {
                  routerLink: 'CoverageVerifier/testcaseExplorer',
                  queryParams: {},
                  queryParamsHandling: 'preserve',
                },
              }],
    };
   this.viewByDropdown ={
    label: 'View by',
    placeholder: '',
    menuItems: [
      { name: 'Test Cases', isActive: false, route: { routerLink: 'CoverageVerfier/testcaseExplorer', queryParams: {}, queryParamsHandling: 'preserve' } },
      { name: 'Test Case Groups', isActive: false, route: { routerLink: 'CoverageVerifier/testCaseGroups', queryParams: {}, queryParamsHandling: 'preserve' } }
    ]
  }; 
  }

  public navigate() {
    this.hideNav = true;
    this.router.navigateByUrl('CoverageVerfier/testcaseExplorer');
  }

  selectionChange(name: string) {
    this.productName = name;
    let routerLink: string;
    this.selectedType = name;
    this.appConfig.menuItems.some((val) => {
      if (val.name === name) {
        routerLink = val.route.routerLink;
      }
    });

    if (routerLink) {

      this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigate([routerLink]));
    } else {
      this.router.navigateByUrl('/');
    }
  }
  groupsSelectionChange(selection: string) {
   if(selection==='Test Case Groups') {
     this.router.navigateByUrl('CoverageVerifier/testCaseGroups');
   }
   else if(selection==='Test Cases'){
    this.router.navigateByUrl('CoverageVerifier/testcaseExplorer');
  }
  else if(selection === null)
  {
    if(this.router.url === '/CoverageVerifier/testcaseExplorer' )
    {
      location.reload(); 
    }else {
    this.viewBy = {label: 'Test Cases'};
    this.router.navigateByUrl('CoverageVerifier/testcaseExplorer');
    }
  }
  
  }
}
