import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TestcasesService } from 'src/app/services/testcases.service';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css']
})
export class DeleteComponent implements OnInit {
  @Input() public user:any;
  errorMessage:string;
  product:any;

  constructor(public modal: NgbActiveModal, public service: TestcasesService) { }

  ngOnInit(): void {
    this.product = this.user?.product;
  }

  onSubmit() {
    let userEmail = this.user?.emailId;
    let authGrp = this.user && this.user?.authorizationGroups?.length ? this.user?.authorizationGroups[0] : null;
    if(!authGrp) return ;
    let authGrpId = authGrp?.authorizationGroupIdentity;
    let revokeRequest = {emailId: userEmail,  authorizationGroupIdentity: authGrpId};
    this.service.revokeUserProductPermission(revokeRequest).subscribe(response => {
      if(response && 'errorCode' in response && response['errorCode'] == 'UM1006') {
        this.errorMessage = "User "+ this.user?.emailId +" does not belong to your company";
      }else{
        this.errorMessage="";
        this.modal.dismiss(response);
      }
    });

  }

  onCancel() {
    this.modal.dismiss(false);
  }

}
