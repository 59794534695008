import { Injectable} from '@angular/core';
import { Actions} from  '@ngrx/effects';
import { TestcasesService } from 'src/app/services/testcases.service';

@Injectable()
export class PolicyDetailEffects{
body:any;
    constructor(private actions$: Actions, private service: TestcasesService){}

    // policySearch$= createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(policyDetailActions.testCaseFilters),
    //         exhaustMap(action =>
    //          this.service.testCases(this.body).pipe(
    //                 map(response =>{
                        
    //                     return policyDetailActions.testCaseData(response)
    //                 }),
    //                 catchError((error:any) => of(policyDetailActions.testCaseError(error)))
    //             ) 
    //         )
    //     )

    // );
}