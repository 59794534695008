import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthModule } from '@uw-verisk/auth';
import { CommonUiModule } from '@uw-verisk/common-ui';
import { rootRouterConfig } from './app.routes';
import { AuthComponent } from '../app/components/shared/auth/auth.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './components/shared/header/header.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { LeftNavComponent } from './components/shared/left-nav/left-nav.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashBoardCardComponent } from './components/shared/dash-board-card/dash-board-card.component';
import { TestCaseExplorerComponent } from './components/test-case-explorer/test-case-explorer.component';
import { ModalPopUpComponent } from './components/shared/modal-pop-up/modal-pop-up.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MultiselectDropdownComponent } from './components/shared/multiselect-dropdown/multiselect-dropdown.component';
import { CookieService } from 'ngx-cookie-service';
import { GridModule } from '@uw-verisk/grid';
import { SidebarModule } from 'ng-sidebar';
import { PolicySearchComponent } from './components/policy-search/policy-search.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PolicyLeftnavComponent } from './components/policy-search/policy-leftnav/policy-leftnav.component';
import { ExplorerLeftnavComponent } from './components/test-case-explorer/explorer-leftnav/explorer-leftnav.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { AppService } from './app.service';
import { TestCaseGroupsComponent } from './components/test-case-groups/test-case-groups.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PolicyDetailEffects } from './store/effects/test-case-explorer-effects';
import { policyInfoReducer } from './store/reducers/test-case-explorer-reducers';
import { ErrorInterceptor } from './components/shared/interceptor/error.interceptor';
import { GroupsModalComponent } from './components/test-case-groups/groups-modal/groups-modal.component';
import { GroupsDetailsComponent } from './components/test-case-groups/groups-details/groups-details.component';
import { ConfirmationDialogComponent } from './components/test-case-groups/confirmation-dialog/confirmation-dialog.component';
import { UsermanagementComponent } from './components/shared/components/usermanagement/usermanagement.component';
import { EditComponent } from './components/shared/components/usermanagement/edit/edit.component';
import { DeleteComponent } from './components/shared/components/usermanagement/delete/delete.component';
import { InviteComponent } from './components/shared/components/usermanagement/invite/invite.component';
import { userPreferencesReducer } from './store/reducers/user-preferences-reducer';
import { filterValueReducer } from './store/reducers/filter-values-reducer';
import { TestCasefiltersEffects } from './store/effects/test-case-filters-effects';
import { VRSK_AUTH_OKTA_CONFIG, VrskAuthOktaAccessTokenInterceptor, VrskAuthOktaModule } from '@uw-verisk/auth-okta';

const appInitializerFn = (configService: AppService) => {
  return () => {
    return configService.setConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    HeaderComponent,
    FooterComponent,
    LeftNavComponent,
    DashboardComponent,
    DashBoardCardComponent,
    TestCaseExplorerComponent,
    ModalPopUpComponent,
    MultiselectDropdownComponent,
    PolicySearchComponent,
    PolicyLeftnavComponent,
    ExplorerLeftnavComponent,
    TestCaseGroupsComponent,
    GroupsModalComponent,
    GroupsDetailsComponent,
    ConfirmationDialogComponent,
    UsermanagementComponent,
    EditComponent,
    DeleteComponent,
    InviteComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    VrskAuthOktaModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    RouterModule.forRoot(rootRouterConfig, { useHash: false }),
    StoreModule.forRoot({
      policyInfo: policyInfoReducer,
      userPreferences: userPreferencesReducer,
      filterValueResponse: filterValueReducer
      }),
    EffectsModule.forRoot([PolicyDetailEffects, TestCasefiltersEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25
  }),
    NgSelectModule,
    AuthModule,
    CommonUiModule,
    GridModule,
    HttpClientModule,
    SidebarModule.forRoot(),
    NgxSliderModule,
  ],
  providers: [
    CookieService,
    DatePipe,
    AppService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VrskAuthOktaAccessTokenInterceptor ,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppService]
    },
    {
      provide: VRSK_AUTH_OKTA_CONFIG,
      useValue: 'assets/jsons/config.json'
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
