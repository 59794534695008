import { createAction, props } from "@ngrx/store";
import { filterValueResponse } from "src/app/models/filterValueResponse";

export const FILTER_VALUES = 'FILTER_VALUES';
export const FILTER_VALUES_SUCCESS = '[Test Case Filter] Success';
export const FILTER_VALUES_ERROR = '[Test Case Filter] Error';

export const testCaseFilters= createAction(
    FILTER_VALUES,
    props<{any}>()
);
export const testCaseFiltersSuccess= createAction(
    FILTER_VALUES_SUCCESS,
    props<filterValueResponse>()
);