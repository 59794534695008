
<div class="page-wrapper">

        <div class="group-label-click" (click)="testCaseGroups()">

          <h1 class="header-label">
            <strong>Test Case Groups</strong>
          </h1>
        </div>
        <h3 class="header-arrow">
          >
        </h3>
        <h1 class="group-name">{{name}}</h1>
        <div [ngClass]="{ 'search-result-grid-disable': loading }"
        ><strong>{{data?.length| number:'1.0'}} results found.</strong></div>
    <div class="inline-block" [ngClass]="{ 'search-result-grid-disable': loading }"
    >Description: <strong> {{description}}</strong> </div>
    <lib-spinner [isBusy]="loading" [backdrop]="false" [type]="'center'" [small]="true"></lib-spinner>
    
      <lib-grid
        #groupDetailsGrid
        class="groupDetailsDataGrid"
        [columns]="cols"
        [data]="data"
        [dataKey]="dataKey"
        [selectableRows]="false"
        [expandableRows]="false"
        [exportData]="false"
        [multiSelectRows]="true"
        [statusOptions]="statusOptions"
        [defaultSort]="defaultSort"
        [globalFilter]="globalFilter"
        [cellTemplates]="{ groupDeleteTemplate: groupDeleteTemplate }"
        (CellClick)="groupCellClick($event)"
        [ngClass]="{ 'search-result-grid-disable': loading }"
        (SelectedRows)="selectedRows($event)"
        (selectionChange)="noOfTestCaseSelection($event)"
        [btnConfig]="buttonConfig"
      >
      </lib-grid>
   
      <ng-template #groupDeleteTemplate let-rowData="rowData" let-field="field">
        <div class="inner-block" (click)="remove(rowData)"> Remove
         </div> 
       </ng-template>
</div>
