import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { State, StatesService } from 'src/app/services/states.service';
import { NgSelectComponent} from '@ng-select/ng-select';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-multiselect-dropdown',
  templateUrl: './multiselect-dropdown.component.html',
  styleUrls: ['./multiselect-dropdown.component.css'],
})
export class MultiselectDropdownComponent implements OnInit {
  states: State[] = [];
  selectedState: string[] = [];
  maxSelect: boolean = false;
  maxStateLimit:number = 10;
  dropdownClose:boolean;
  @Output() onSelect = new EventEmitter();
  @Input() statesInput: string[] = [];
  @Input() isEmpty: any;
  @ViewChild('Selecter') ngselect: NgSelectComponent;
  constructor(
    private dataService: StatesService,
    private cookie: CookieService
  ) {}

  ngOnInit() {
    this.dataService
      .getStates()
      .pipe(map((x) => x.filter((y) => !y.disabled)))
      .subscribe((res) => {
        this.states = res;
      });
    if (this.cookie.get('state') != '') {
      let temp = JSON.parse(this.cookie.get('state'));
      temp = temp.map((_) => _.index);
      this.selectedState = temp;
      if (this.selectedState.length == this.maxStateLimit) {
        this.maxSelect = true;
      } else {
        this.maxSelect = false;
      }
    }
  }
  ngOnChanges() {
    this.selectedState = this.statesInput;
  }

  stateSelection() {
    let filteredStates = this.states.filter((item) =>
      this.selectedState.includes(item.index)
    );
    this.onSelect.emit(filteredStates);
    if (filteredStates.length == this.maxStateLimit) {
      this.maxSelect = true;
      this.ngselect.close();
    } else {
      this.maxSelect = false;
    }
  }
}
