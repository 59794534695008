import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '@uw-verisk/common-ui';
import { GridComponent } from '@uw-verisk/grid';
import { TestcasesService } from 'src/app/services/testcases.service';

@Component({
  selector: 'app-groups-modal',
  templateUrl: './groups-modal.component.html',
  styleUrls: ['./groups-modal.component.css']
})
export class GroupsModalComponent implements OnInit {
  uniqueId: any;
  groupsDropdown: any; 
  groupForm: FormGroup;
  errorMessage: any;
  requestBody: any;
  label: any;
  name: any;
  description: any;
  buttonDisable ;
  minTestCase = false;
  maxGroups = false;
  loading = true;
  nameRequired = false;
  cols: any = [
    {
      field: 'testCaseId',
      header: 'Test Case ID',
      filter: false,
      sortable: false,
      qspassthrough: true
    }, 
    {
      field: 'state',
      header: 'State',
      sortable: false,
      qspassthrough: true
    },
    {
      field: 'noOfPolicies',
      header: 'Policy Count',
      sortable: false,
      qspassthrough: true
    },
    {
      field: '',
      header: '',
      sortable: false,
      cellTemplate: 'removeTestCaseTemplate',
      qspassthrough: true
    }
   
  
  ];
  dataKey: any = 'testCaseId';
  defaultSort = { field: 'dateAdded', sortMode: -1 };
  statusOptions: any = { OPEN: 'label-success', EXPIRED: 'label-warning' };
  globalFilter = '';
  @ViewChild('groupsModal') explorerGridRef: GridComponent;
  
  constructor(private groupsService : TestcasesService,private forms: FormBuilder,public activeModal: NgbActiveModal,
    private toastservice :ToastService) { }

  ngOnInit(): void {
    if(this.label === "Add To Test Case Group")
    {
      this.buttonDisable = true;
      this.groupsService.getSpinnerObserver().subscribe((status) => {
        this.loading = status === 'start';
      });
    this.groupsService.getAllTestCaseGroups().subscribe((data) =>
    {
      this.groupsDropdown = data.map((x)=>({
        "testCaseGroupUniqueId": x.testCaseGroupUniqueId,
        "name": x.name
      }));
      if(this.groupsDropdown?.length >= 10) {
        this.groupForm.get('name').disable();
      this.groupForm.get('description').disable();
      this.maxGroups = true;
      }

    } );
    this.groupForm = this.forms.group({
      testCaseGroupUniqueId: new FormControl(),
      name: new FormControl(),
      description: new FormControl(),
    }); 
    this.groupForm.valueChanges.subscribe(() => {
      
      if(this.groupForm.controls['name'].value?.length > 0 ||
      this.groupForm.controls['testCaseGroupUniqueId'].value?.length > 0) {
        this.buttonDisable = false;
      } 
      if((this.groupForm.controls['name'].value?.length == 0 || this.groupForm.controls['name'].value?.length === undefined) 
      && (this.groupForm.controls['description'].value?.length == 0 || this.groupForm.controls['description'].value?.length === undefined)
      && (this.groupForm.controls['testCaseGroupUniqueId'].value?.length == 0 || this.groupForm.controls['testCaseGroupUniqueId'].value?.length === undefined)) {
        this.buttonDisable = true;
      } 
      if (this.groupForm.controls['name'].value?.length > 0 ||
      this.groupForm.controls['description'].value?.length > 0) {
         this.groupForm.controls['testCaseGroupUniqueId'].disable({ onlySelf:true});
      }
      
      else {
        this.groupForm.controls['testCaseGroupUniqueId'].enable({ onlySelf:true});
      }
   
      if((this.groupForm.controls['name'].value?.length == 0 || this.groupForm.controls['name'].value?.length === undefined) && this.groupForm.controls['description'].value?.length > 0 ) {
       this.nameRequired = true;
      } 
      else {
        this.nameRequired = false;
      }
    }); 
    
    
  }
  else if (this.label === "Edit Test Case Group")
  {
    this.buttonDisable = true;
    this.groupForm = this.forms.group({
      name: new FormControl(this.name),
      description: new FormControl(this.description),
    }); 
    this.groupForm.valueChanges.subscribe(() => {
      if(this.groupForm.controls['name'].dirty || this.groupForm.controls['description'].dirty) {
        this.buttonDisable = false;
      }
    });
  }
  
    }
    
    testCaseAction()
    {
      if(this.label=== "Add To Test Case Group")
      {
        this.createGroup();
      }
      else if(this.label === "Edit Test Case Group")
      {
         this.editGroup();
      }
    }
  removeTestCase(event) {
   const index = this.uniqueId.indexOf(event);
   if(this.uniqueId.length > 1) {
     this.uniqueId = this.uniqueId.slice(0, index).concat( this.uniqueId.slice(index+1) );
   }
   else
    this.minTestCase = true;
  }
  createGroup() {
    this.requestBody = this.groupForm.value;
    if(this.requestBody.name === null) {
      this.requestBody.name = "";
    }
    if(this.requestBody.testCaseGroupUniqueId === null) {
      this.requestBody.testCaseGroupUniqueId = "";
    }
    if(this.requestBody.description === null) {
      this.requestBody.description = "";
    }
    this.requestBody.testCaseUniqueIds = this.uniqueId.map(x => x.uniqueId);
    this.requestBody.productCode = "CV";
    this.groupsService.insertTestCaseGroup(this.requestBody).subscribe(resp => {
      if(resp && resp.errorMessage != null)  {
        this.errorMessage = resp.errorMessage;
      }else { 
        this.errorMessage="";
        this.toastservice.success("Group Successfully created");
        this.activeModal.close();
      }   
  });
  }
  editGroup() {
    this.requestBody = this.groupForm.value;
    this.requestBody.testCaseGroupUniqueId = this.uniqueId;
    this.groupsService.editTestCaseGroup(this.requestBody).subscribe(resp => {
      if(resp && resp.errorMessage != null)  {
        this.errorMessage = resp.errorMessage;
      }else { 
        this.errorMessage="";
        this.toastservice.success("Group Successfully Edited");
        this.activeModal.close();
      }   
  });
  }
  inputChange() {
    if(this.groupForm.get('testCaseGroupUniqueId').value?.length > 0)
    {
      this.groupForm.get('name').disable();
      this.groupForm.get('description').disable();
    }
    else {
      this.groupForm.get('name').enable();
      this.groupForm.get('description').enable();
    }
  }
  inputEnable() {
    
    this.groupForm.get('name').enable();
    this.groupForm.get('description').enable();
    if(this.groupForm.controls['name'].value?.length > 0 &&
      this.groupForm.controls['description'].value?.length > 0) {
        this.buttonDisable = true;
      }
      
  }
}
