import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { IMenuConfig, IHeaderTitle } from '@uw-verisk/common-ui';
import { IAppConfig } from '../../../models/config';
import { AppService } from '../../../app.service';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  
  profile: string;
  appConfig: IAppConfig;
  title :IHeaderTitle;

  constructor(private appService: AppService, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,private router: Router) { 
    this.profile = 'John Smith';
  }

  
  ngOnInit(){
    this.appConfig = this.appService.readConfig();
    this.title = {
      name: this.appConfig.applicationName,
    }
    this.routerChange();
  }
  menuItems: IMenuConfig[] = [
    { name: 'PRODUCT', isActive: true, route: {​​​​​​​​routerLink:'/', queryParams: {​​​​​​​​}​​​​​​​​, queryParamsHandling:'preserve'},
     }
  ]

  routerChange() {

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if(event.url.includes('userManagement'))
        {
          this.menuItems[this.menuItems.findIndex((obj => obj.name === 'USER MANAGEMENT'))].isActive = true;
          this.menuItems[this.menuItems.findIndex((obj => obj.name === 'PRODUCT'))].isActive = false;
        }
        else {
          this.menuItems[this.menuItems.findIndex((obj => obj.name === 'PRODUCT'))].isActive = true;
        }
      }
    });
  }
    
  public onLogout() {
    this.oktaAuth?.signOut();
  }

  onTitleClick() {
    this.router.navigateByUrl('/');
  }
 
}
