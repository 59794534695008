
<div class="policy-leftnav-content">
  <div (click)="goBack()" class="back-tag">
    <i class="va-icon-keyboard_backspace mr-1"></i
    ><span class="policy-text pointer-cursor"
      >Back to Test Case Results</span
    >
  </div>
  <br />
  <div class="pagination-wrapper mt-4">
    <div class="label ml-2">Transactions </div>
    <div class="pagination-block">
      <div class="inner-block">
        <i
          class="va-icon-keyboard_arrow_left previous mr-3 pointer-cursor"
          (click)="transactionsDown()"
        ></i
        ><span class="status"
          ><span class="start-page mx-3">{{
            transactionDetails?.transactions.findIndex(getIndex) + 1
              | number : "2.0"
          }}</span
          ><span class="of">of
            </span
          ><span class="end-page mx-3">{{
            transactionDetails?.transactions.length | number : "2.0"
          }}</span></span
        ><i
          class="va-icon-keyboard_arrow_right next ml-3 pointer-cursor"
          (click)="transactionsUp()"          
        ></i>
      </div>
    </div>
  </div>

  <div class="policies-wrapper mt-5">
    <div class="label ml-2 policy-content">Policies </div>
    <div *ngFor="let element of policyDetails?.policies; let i = index">
      <div class="policies-block">
        <div
          class="inner-block pointer-cursor"
          [ngClass]="{ active: element.policyNumber == selectedPolicy }"
        >
          <i
            class="va-icon-arrow_drop_down"
            *ngIf="element.isActive"
            (click)="PolicyClick($event, i)"
          ></i>
          <i
            class="va-icon-play_arrow"
            *ngIf="!element.isActive"
            (click)="PolicyClick($event, i)"
          ></i>
          <span class="policy-number" (click)="PolicyClick($event, i)">{{
            element.policyNumber
          }}</span>
        </div>
      </div>
      <ul
        class="policy-transactions pl-4"
        [ngClass]="{ hide: !element.isActive }"
      >
        <ng-container *ngFor="let item of element?.transactions; let j = index">
          <li class="mt-2">
            <a
              [ngClass]="{
                active:
                  j == transactionDetails?.transactions.findIndex(getIndex) &&
                  item.transactionKey == selectedTransaction
              }"
              (click)="transactionClick(item, element)"
              >{{ item.transactionId }}</a
            >
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
