import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { PolicyDetailsService } from 'src/app/services/policy-details.service';

@Component({
  selector: 'app-policy-leftnav',
  templateUrl: './policy-leftnav.component.html',
  styleUrls: ['./policy-leftnav.component.css'],
})
export class PolicyLeftnavComponent implements OnInit {
  policies: any = {};
  policyDetails: any;
  transactionDetails: any;
  selectedPolicy: any;
  body: any;
  selectedTransaction: any;
  getIndex = (element) => {
    if (element.transactionKey == this.selectedTransaction) {
      return true;
    }
  };
  constructor(
    private router: Router,
    private policyService: PolicyDetailsService, private location: Location
  ) {}

  ngOnInit(): void {
    this.body = localStorage.getItem('uniqueIds');

    this.policyService.policies.subscribe((data) => {
      if (Object.keys(data).length > 0) {
        this.policyDetails = data;
        this.selectedPolicy = this.policyDetails.policies[0].policyNumber;
        this.policyDetails.policies[0].isActive = true;
        this.transactionDetails = data.policies[0];
        this.policyService.transactionDetails.next(
          data.policies[0].transactions[0]
        );
        this.selectedTransaction =
          data.policies[0].transactions[0].transactionKey;
      }
    });
  }

  goBack() {
    this.location.back();
    this.policyService.policies.next("");
  }
  transactionClick(transaction, policy) {
    this.selectedPolicy = policy.policyNumber;
    this.transactionDetails = policy;
    this.policyService.policyDetails.next(policy);
    this.policyService.transactionDetails.next(transaction);
    this.selectedTransaction = transaction.transactionKey;
  }
  PolicyClick(event, index) {
    const element = event.target;
    element.classList.toggle('active');
    if (this.policyDetails.policies[index].isActive) {
      this.policyDetails.policies[index].isActive = false;
    } else {
      this.policyDetails.policies[index].isActive = true;
    }
  }
  transactionsUp() {
    if (
      this.transactionDetails?.transactions.findIndex(this.getIndex) <
      this.transactionDetails?.transactions.length - 1
    ) {
      this.selectedTransaction =
        this.transactionDetails.transactions[
          this.transactionDetails.transactions.findIndex(this.getIndex) + 1
        ].transactionKey;
      this.policyService.transactionDetails.next(
        this.transactionDetails.transactions[
          this.transactionDetails.transactions.findIndex(this.getIndex)
        ]
      );
    }
  }
  transactionsDown() {
    if (this.transactionDetails?.transactions.findIndex(this.getIndex) > 0) {
      this.selectedTransaction =
        this.transactionDetails.transactions[
          this.transactionDetails.transactions.findIndex(this.getIndex) - 1
        ].transactionKey;
      this.policyService.transactionDetails.next(
        this.transactionDetails.transactions[
          this.transactionDetails.transactions.findIndex(this.getIndex)
        ]
      );
    }
  }
}
