
<app-multiselect-dropdown
  (onSelect)="statesSelected($event)"
  [statesInput]="statesInput"
  [isEmpty]="isEmpty"
></app-multiselect-dropdown>
<div *ngIf="isEmpty" class="required-label">* Required</div>
<div id="additionalFilters" *ngIf="!showMore" (click)="showAdditionalFilters()">
  + Show Additional Filters
</div>
<div *ngIf="showMore">
  <div class="left-nav-accordion">
    <div class="left-nav-layout">
      <ng-select
        #coverage
        id="coverage"
        class="state-multiselect"
        [items]="coverages"
        [multiple]="true"
        bindLabel="description"
        bindValue="code"
        [selectableGroup]="true"
        [closeOnSelect]="false"
        [clearSearchOnAdd]="true"
        [selectableGroupAsModel]="false"
        [(ngModel)]="coveragesSelection"
        placeholder="Coverages"
        dropdownPosition="bottom"
        [maxSelectedItems]="maxCoverageLimit"
        (add)="coverageSelection()"
        (remove)="coverageSelection()"
        (clear)="coverageSelection()"
      >
        <ng-template
          ng-option-tmp
          let-item="item"
          let-item$="item$"
          let-index="index"
        >
          <div title="{{ item.toolTip }}">
            <input
              id="item-{{ index }}"
              type="checkbox"
              [ngModel]="item$.selected"
            />
            {{ item.description }}
          </div>
        </ng-template>
      </ng-select>
      <div *ngIf="coverageMaxSelect" class="maximum-selection-label">
        * Maximum Selection Reached
      </div>
      <div class="policy_dropdown_grid">
        <ng-select
          id="policyType"
          class="accordian policy"
          [items]="policyType"
          bindLabel="code"
          bindValue="code"
          placeholder="Policy Type"
          [(ngModel)]="policyTypeSelection"
          dropdownPosition="bottom"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.description }}">{{ item.code }}</div>
          </ng-template>
        </ng-select>
        <ng-select
          id="policyStatus"
          class="accordian policy"
          [items]="policyStatus"
          bindLabel="code"
          bindValue="code"
          placeholder="Policy Status"
          [(ngModel)]="policyStatusSelection"
          dropdownPosition="bottom"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.description }}">{{ item.code }}</div>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="left-nav-layout">
      <ng-select
        id="lookBackPeriod"
        class="accordian"
        [items]="lookBackPeriod"
        bindLabel="description"
        bindValue="code"
        placeholder="Lookback Period"
        [(ngModel)]="lookBackPeriodSelection"
        dropdownPosition="bottom"
      >
      </ng-select>
    </div>

    <div class="left-nav-layout">
      <ng-select
        id="multipleCarriers"
        class="accordian"
        [items]="mulitpleCarriersDropdown"
        bindLabel="description"
        bindValue="code"
        placeholder="Multiple Carriers"
        [(ngModel)]="carrierSelected"
        dropdownPosition="bottom"
      >
      </ng-select>
    </div>
    <div class="left-nav-layout">
      <ng-select
        id="lapseInCoverage"
        class="accordian"
        [items]="mulitpleCarriersDropdown"
        bindLabel="description"
        bindValue="code"
        placeholder="Lapse In Coverage"
        [(ngModel)]="lapseSelected"
        dropdownPosition="bottom"
      >
      </ng-select>
    </div>

    <div>
      <p class="left-nav-slider-label">Policy Count</p>
      <div class="custom">
        <ngx-slider
          id="policyCount"
          [(value)]="policyCountLowValue"
          [(highValue)]="policyCountHighValue"
          [options]="poptions"
        ></ngx-slider>
      </div>
    </div>
    <div>
      <p class="driver-slider-label">Number of Drivers</p>
      <div class="custom">
        <ngx-slider
          id="drivers"
          [(value)]="driverLowValue"
          [(highValue)]="driverHighValue"
          [options]="doptions"
        ></ngx-slider>
      </div>
    </div>
    <div>
      <p class="left-nav-slider-label">Number of Vehicles</p>
      <div class="custom">
        <ngx-slider
          id="vehicles"
          [(value)]="vehicleLowValue"
          [(highValue)]="vehicleHighValue"
          [options]="options"
        ></ngx-slider>
      </div>
    </div>
  </div>
</div>

<div class="left-nav-button">
  <button
    id="filters"
    type="button"
    class="btn btn-warning btw"
    (click)="saveFilter()"
    [disabled]="isEmpty || productRemoved"
  >
    Apply Filters
  </button>
  <span class="left-nav-or-label">or
  </span>
  <span class="resetFilter" (click)="!isEmpty && resetFilters()">Reset </span>
</div>
