import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dash-board-card',
  templateUrl: './dash-board-card.component.html',
  styleUrls: ['./dash-board-card.component.css'],
})
export class DashBoardCardComponent {
  @Input()
  set data(value: any) {
    this.code = value.code;
    this.name = value.name;
    this.description = value.description;
    this.noOfTestCases = value.noOfTestCases;
    this.lastRefreshed = value.lastRefreshed;
  }

  public code: string = '';
  public name: string = '';
  public description: string = '';
  public noOfTestCases: number = 0;
  public lastRefreshed: string = '';

}
