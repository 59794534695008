import { Component,Inject,OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { UserPreferenceResponse } from '@uw-verisk/auth';
import { AppService } from './app.service';
import { IAppConfig } from './models/config'
import { testCaseFilters } from './store/actions/filter-value-action';
import { VIEW_PERMISSIONS } from './store/actions/user-preferences-action';
import { AppState } from './store/app.state';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { Observable } from 'rxjs';

enum MENU_MODES {
  OVER= 'over',
  PUSH= 'push',
  SLIDE= 'slide'
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  _opened: boolean = true;
  _MODES: MENU_MODES  = MENU_MODES.PUSH;
   appConfig: IAppConfig;
   userApiUrl :string; 
   public isAuthenticated$!: Observable<boolean>;
  public name$: Observable<string>;
  //  _MODES: Array<string> = ['over', 'push', 'slide'];
  constructor(private appService: AppService,private router: Router,private modalService: NgbModal, private store: Store<AppState>,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth, public oktaAuthSvc: OktaAuthStateService){
      this.appConfig = this.appService.readConfig();
    }
  
    async ngOnInit() {
      let isAuthenticated = await this.oktaAuth.isAuthenticated();
      if (isAuthenticated) {
        this.initialize();
      }
    }
  initialize()
  {

    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {

        this.modalService.dismissAll();        

      }

    });
    this.store.dispatch(testCaseFilters({any:{}}));
    this.userApiUrl = this.appService.configuration.contributionsApiUrl +'Security/GetExternalPortalUserInfo';
    
  }
  
  userInfo(resp: UserPreferenceResponse) {
    this.store.dispatch({
      type: VIEW_PERMISSIONS,
      payload: resp
    });
  }

}
