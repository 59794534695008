
<div class="tab_wrapper">
  <lib-spinner
    [isBusy]="loading"
    [backdrop]="false"
    [type]="'center'"
    [small]="true"
  ></lib-spinner>
  <div>
    <div [ngClass]="{ 'policy-search-grid-disable': !isLoaded }">
      <div class="policy-search-body">
        <div>
          <small class="policy-breadcrumb-label">Test Case</small>
          <h3 class="h3-header">
            <strong>{{ policy?.testCaseId }}</strong>
          </h3>
        </div>
        <h3 class="h3-arrow">
          >
        </h3>
        <div>
          <small class="policy-breadcrumb-label">Policy </small>
          <h3 class="h3-header">
            <strong>{{ policyDetails?.policyNumber }}</strong>
          </h3>
        </div>
        <h3 class="h3-arrow">
          >
        </h3>
        <div>
          <small class="policy-breadcrumb-label">Transaction </small>
          <h3 class="h3-transaction">
            {{ tabData?.transactionId }}
          </h3>
        </div>
      </div>

      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs mt-4">
        <li [ngbNavItem]="1">
          <a ngbNavLink>Policy Information</a>
          <ng-template ngbNavContent>
            <div class="policy-tabs">
              <hr />
              <div class="policy-search-grid">
                <div>
                  <h2 class="policy-information-heading">Transactions </h2>
                  <div class="policy-search-grid-layout">
                    <p>Transaction Code:</p>
                    <strong>{{ tabData?.information?.transactionType }}</strong>
                    <p>Company Name:</p>
                    <strong>{{ tabData?.information?.companyName }}</strong>
                    <p>AMBest Number:</p>
                    <strong>{{ policyDetails?.amBestNumber }}</strong>
                    <p>Line of Business:</p>
                    <strong>{{ policyDetails?.lineOfBusiness }}</strong>
                    <p>NAIC Code:</p>
                    <strong>{{ tabData?.information?.naicCode }}</strong>
                    <p>Risk Class:</p>
                    <strong>{{ tabData?.information?.riskClass }}</strong>
                  </div>
                </div>

                <div>
                  <h2 class="policy-information-heading">Policy </h2>
                  <div class="policy-search-grid-layout">
                    <p>Policy Number:</p>
                    <strong>{{ policyDetails?.policyNumber }}</strong>
                    <p>Policy Type:</p>
                    <strong>{{ tabData?.information?.policyClass }}</strong>
                    <p>Inception Date:</p>
                    <strong>{{ tabData?.information?.inceptionDate }}</strong>
                    <p>Policy Term:</p>
                    <strong>{{ tabData?.information?.term }}</strong>
                    <p>Effective Date:</p>
                    <strong>{{
                      tabData?.information?.termEffectiveDate
                    }}</strong>
                    <p>Cancellation Date:</p>
                    <strong>{{
                      tabData?.information?.cancellationDate
                    }}</strong>
                    <p>Policy Status:</p>
                    <strong>{{ tabData?.information?.status }}</strong>
                  </div>
                </div>

                <div>
                  <h2 class="policy-information-heading">Contact </h2>
                  <div class="policy-search-grid-layout">
                    <p>Address:</p>
                    <strong
                      >{{
                        tabData?.information?.policyAddress.street1 | uppercase
                      }}
                      {{
                        tabData?.information?.policyAddress.street2 | uppercase
                      }}<br />{{
                        tabData?.information?.policyAddress.city | uppercase
                      }},
                      {{
                        tabData?.information?.policyAddress.stateCode
                          | uppercase
                      }}
                      {{ tabData?.information?.policyAddress.zip }}
                      {{
                        tabData?.information?.policyAddress.country | uppercase
                      }}</strong
                    >
                    <p>Home Phone:</p>
                    <strong>{{
                      tabData?.information?.homePhone == ""
                        ? ""
                        : (tabData?.information?.homePhone | slice : 0 : 7) +
                          "-" +
                          (tabData?.information?.homePhone | slice : 7 : 11) +
                          " " +
                          (homeExtension?.length == 4 && homeExtension != 0000
                            ? "Ext: " + homeExtension
                            : "")
                    }}</strong>
                    <p>Mobile Phone:</p>
                    <strong>{{
                      tabData?.information?.mobilePhone == ""
                        ? ""
                        : (tabData?.information?.mobilePhone | slice : 0 : 7) +
                          "-" +
                          (tabData?.information?.mobilePhone | slice : 7 : 11) +
                          " " +
                          (mobileExtension?.length == 4 &&
                          mobileExtension != 0000
                            ? "Ext: " + mobileExtension
                            : "")
                    }}</strong>
                    <p>Office Phone:</p>
                    <strong>{{
                      tabData?.information?.officePhone == ""
                        ? ""
                        : (tabData?.information?.officePhone | slice : 0 : 7) +
                          "-" +
                          (tabData?.information?.officePhone | slice : 7 : 11) +
                          " " +
                          (officeExtension?.length == 4 &&
                          officeExtension != 0000
                            ? "Ext: " + officeExtension
                            : "")
                    }}</strong>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>

        <li [ngbNavItem]="2">
          <a ngbNavLink>Vehicle Information</a>
          <ng-template ngbNavContent>
            <div class="policy-tabs">
              <div class="label-vehicles">
                {{ tabData?.vehicles?.length }} Vehicle(s) Found
              </div>
              <ng-select
                id="vehicles"
                class="policy-search-dropdown"
                [(ngModel)]="vehicleSelected"
              >
                <ng-option
                  *ngFor="let vehicle of tabData?.vehicles"
                  [value]="vehicle"
                >
                  {{ vehicle.year ? vehicle.year + " " : ""
                  }}{{ (vehicle.make ? vehicle.make + " " : "") | titlecase
                  }}{{ (vehicle.model ? vehicle.model : "") | titlecase }}
                </ng-option>
              </ng-select>
              <div class="policy-search-grid">
                <div>
                  <h2>Automobile </h2>
                  <div class="policy-search-grid-layout">
                    <p>Year:</p>
                    <strong>{{
                      vehicleSelected && vehicleSelected.year
                        ? vehicleSelected.year
                        : ""
                    }}</strong>
                    <p>Make:</p>
                    <strong>{{
                      vehicleSelected && vehicleSelected.make
                        ? vehicleSelected.make
                        : ""
                    }}</strong>
                    <p>Model:</p>
                    <strong>{{
                      vehicleSelected && vehicleSelected.model
                        ? vehicleSelected.model
                        : ""
                    }}</strong>
                    <p>VIN:</p>
                    <strong>{{
                      vehicleSelected && vehicleSelected.vin
                        ? vehicleSelected.vin
                        : ""
                    }}</strong>
                    <p>Business Use:</p>
                    <strong>{{
                      vehicleSelected && vehicleSelected.businessUse
                        ? vehicleSelected.businessUse
                        : ""
                    }}</strong>
                    <p>Anti-theft Device:</p>
                    <strong>{{
                      vehicleSelected && vehicleSelected.antiTheftDevice
                        ? vehicleSelected.antiTheftDevice
                        : ""
                    }}</strong>
                  </div>
                </div>
                <div>
                  <h2>Lender </h2>
                  <div class="policy-search-grid-layout">
                    <p>Lender Name:</p>
                    <strong>{{
                      vehicleSelected &&
                      vehicleSelected.lenderInformation.lenderName
                        ? vehicleSelected.lenderInformation.lenderName
                        : ""
                    }}</strong>
                    <p>Address:</p>
                    <strong>
                      {{
                        vehicleSelected &&
                        vehicleSelected.lenderInformation.address &&
                        vehicleSelected.lenderInformation.address.street1 &&
                        (vehicleSelected.lenderInformation.address.street1 ||
                          vehicleSelected.lenderInformation.address.street2)
                          ? (vehicleSelected.lenderInformation.address.street1
                            | uppercase)
                          : ""
                      }}
                      {{
                        vehicleSelected &&
                        vehicleSelected.lenderInformation.address &&
                        vehicleSelected.lenderInformation.address.street2 &&
                        (vehicleSelected.lenderInformation.address.street1 ||
                          vehicleSelected.lenderInformation.address.street2)
                          ? (vehicleSelected.lenderInformation.address.street2
                            | uppercase)
                          : ""
                      }}
                      <br />
                      {{
                        vehicleSelected &&
                        vehicleSelected.lenderInformation.address &&
                        vehicleSelected.lenderInformation.address.city
                          ? (vehicleSelected.lenderInformation.address.city
                              | uppercase) + ", "
                          : ""
                      }}

                      {{
                        vehicleSelected &&
                        vehicleSelected.lenderInformation.address &&
                        vehicleSelected.lenderInformation.address.stateCode
                          ? (vehicleSelected.lenderInformation.address.stateCode
                            | uppercase)
                          : ""
                      }}
                      {{
                        vehicleSelected &&
                        vehicleSelected.lenderInformation.address &&
                        vehicleSelected.lenderInformation.address.zip
                          ? vehicleSelected.lenderInformation.address.zip
                          : ""
                      }}
                      {{
                        vehicleSelected &&
                        vehicleSelected.lenderInformation.address &&
                        vehicleSelected.lenderInformation.address.country
                          ? (vehicleSelected.lenderInformation.address.country
                            | uppercase)
                          : ""
                      }}
                    </strong>
                    <p>Loan:</p>
                    <strong>{{
                      vehicleSelected && vehicleSelected.loanNumber
                        ? vehicleSelected.loanNumber
                        : ""
                    }}</strong>
                    <p>ABA:</p>
                    <strong>{{
                      vehicleSelected &&
                      vehicleSelected.lenderInformation.abaNumber
                        ? vehicleSelected.lenderInformation.abaNumber
                        : ""
                    }}</strong>
                  </div>
                </div>
                <div>
                  <h2>Garage Location</h2>
                  <div class="policy-search-grid-layout">
                    <p>Address:</p>
                    <strong>
                      {{
                        vehicleSelected &&
                        vehicleSelected.garageLocation &&
                        vehicleSelected.garageLocation.street1 &&
                        (vehicleSelected.garageLocation.street1 ||
                          vehicleSelected.garageLocation.street2)
                          ? (vehicleSelected.garageLocation.street1 | uppercase)
                          : ""
                      }}
                      {{
                        vehicleSelected &&
                        vehicleSelected.garageLocation &&
                        vehicleSelected.garageLocation.street2 &&
                        (vehicleSelected.garageLocation.street1 ||
                          vehicleSelected.garageLocation.street2)
                          ? (vehicleSelected.garageLocation.street2 | uppercase)
                          : ""
                      }}
                      <br />
                      {{
                        vehicleSelected &&
                        vehicleSelected.garageLocation &&
                        vehicleSelected.garageLocation.city
                          ? (vehicleSelected.garageLocation.city | uppercase) +
                            ", "
                          : ""
                      }}

                      {{
                        vehicleSelected &&
                        vehicleSelected.garageLocation &&
                        vehicleSelected.garageLocation.stateCode
                          ? (vehicleSelected.garageLocation.stateCode
                            | uppercase)
                          : ""
                      }}

                      {{
                        vehicleSelected &&
                        vehicleSelected.garageLocation &&
                        vehicleSelected.garageLocation.zip
                          ? vehicleSelected.garageLocation.zip
                          : ""
                      }}
                      {{
                        vehicleSelected &&
                        vehicleSelected.garageLocation &&
                        vehicleSelected.garageLocation.country
                          ? (vehicleSelected.garageLocation.country | uppercase)
                          : ""
                      }}
                    </strong>
                  </div>
                </div>
              </div>
              <div class="vehicle-info-wrapper mt-4">
                <div class="row">
                  <h2 class="col-md-4 section-header my-2">Coverage </h2>
                </div>

                <div class="row">
                  <div class="coverage-grid">
                    <div class="policy-search-grid-layout">
                      <p class="collision-deductible">Collision Deductible:</p>
                      <strong class="collision-deductible-label">{{
                        vehicleSelected && vehicleSelected.collisionDeductible
                          ? "$ " +
                            (vehicleSelected.collisionDeductible
                              | number : "1.0")
                          : ""
                      }}</strong>
                    </div>
                    <div class="policy-search-grid-layout">
                      <p>Comprehensive Deductible:</p>

                      <strong class="collision-deductible-label">{{
                        vehicleSelected &&
                        vehicleSelected.comprehensiveDeductible
                          ? "$ " +
                            (vehicleSelected.comprehensiveDeductible
                              | number : "1.0")
                          : ""
                      }}</strong>
                    </div>
                  </div>
                </div>
                <table class="mt-4">
                  <thead>
                    <th class="py-2">Coverage Type</th>
                    <th class="py-2">Individual Limit</th>
                    <th class="py-2">Ocurrence Limit</th>
                    <th class="py-2">Combined Single Limit</th>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let policyCoverage of vehicleSelected?.coverages"
                    >
                      <td class="py-2">
                        {{
                          policyCoverage.coverageType
                            ? policyCoverage.coverageType
                            : ""
                        }}
                      </td>
                      <td class="py-2">
                        {{
                          policyCoverage.individualLimit
                            ? "$ " +
                              (policyCoverage.individualLimit | number : "1.0")
                            : ""
                        }}
                      </td>
                      <td class="py-2">
                        {{
                          policyCoverage.occurrenceLimit
                            ? "$ " +
                              (policyCoverage.occurrenceLimit | number : "1.0")
                            : ""
                        }}
                      </td>
                      <td class="py-2">
                        {{
                          policyCoverage.combinedSingleLimit
                            ? "$ " +
                              (policyCoverage.combinedSingleLimit
                                | number : "1.0")
                            : ""
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </ng-template>
        </li>

        <li [ngbNavItem]="3">
          <a ngbNavLink>Subject Information</a>
          <ng-template ngbNavContent>
            <div class="policy-tabs">
              <div class="label-subjects">
                {{ tabData?.subjects?.length }} Subject(s) Found
              </div>
              <ng-select
                class="policy-search-dropdown"
                id="subjects"
                [(ngModel)]="subjectSelected"
              >
                <ng-option
                  *ngFor="let subjectInfo of tabData?.subjects"
                  [value]="subjectInfo"
                >
                  {{
                    (subjectInfo.firstName ? subjectInfo.firstName + " " : "")
                      | titlecase
                  }}{{
                    (subjectInfo.lastName ? subjectInfo.lastName : "")
                      | titlecase
                  }}
                </ng-option>
              </ng-select>
              <div class="subject-grid">
                <div class="policy-search-grid-layout">
                  <p>First Name:</p>
                  <strong>{{
                    subjectSelected && subjectSelected.firstName
                      ? subjectSelected.firstName
                      : ""
                  }}</strong>
                  <p>Last Name:</p>
                  <strong>{{
                    subjectSelected && subjectSelected.lastName
                      ? subjectSelected.lastName
                      : ""
                  }}</strong>
                  <p>Middle Name:</p>
                  <strong>{{
                    subjectSelected && subjectSelected.middleName
                      ? subjectSelected.middleName
                      : ""
                  }}</strong>
                  <p>Suffix:</p>
                  <strong>{{
                    subjectSelected && subjectSelected.suffix
                      ? subjectSelected.suffix
                      : ""
                  }}</strong>
                  <p>Gender:</p>
                  <strong>{{
                    subjectSelected && subjectSelected.gender
                      ? subjectSelected.gender
                      : ""
                  }}</strong>
                  <p>Relationship to Policyholder:</p>
                  <strong>{{
                    subjectSelected && subjectSelected.relationToPolicyHolder
                      ? subjectSelected.relationToPolicyHolder
                      : ""
                  }}</strong>
                </div>
                <div class="policy-search-grid-layout">
                  <p>Date of Birth:</p>
                  <strong>{{
                    subjectSelected && subjectSelected.dateOfBirth
                      ? subjectSelected.dateOfBirth
                      : ""
                  }}</strong>
                  <p>Marital Status:</p>
                  <strong>{{
                    subjectSelected && subjectSelected.maritalStatus
                      ? subjectSelected.maritalStatus
                      : ""
                  }}</strong>
                  <p>Driver's Licence Number:</p>
                  <strong>{{
                    subjectSelected && subjectSelected.dlNumber
                      ? subjectSelected.dlNumber
                      : ""
                  }}</strong>
                  <p>Driver's Licence State:</p>
                  <strong>{{
                    subjectSelected && subjectSelected.dlState
                      ? subjectSelected.dlState
                      : ""
                  }}</strong>
                  <p>SSN:</p>
                  <strong>{{
                    subjectSelected && subjectSelected.ssn
                      ? (subjectSelected.ssn | slice : 0 : 3) +
                        "-" +
                        (subjectSelected.ssn | slice : 3 : 5) +
                        "-" +
                        (subjectSelected.ssn | slice : 5 : 9)
                      : ""
                  }}</strong>
                  <p>Relationship to Insured:</p>
                  <strong>{{
                    subjectSelected && subjectSelected.relationToInsured
                      ? subjectSelected.relationToInsured
                      : ""
                  }}</strong>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>

    <div
      class="alert alert-info policy-search-grid-disable"
      [ngClass]="{ 'search-result-no-records': showNoRecords }"
    >
      No records to display for Test Case.
    </div>
  </div>
</div>
